import React, { useRef } from "react";

import { AiOutlineMail } from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
import emailjs from "emailjs-com";
import Navb from "../nav/Navb";
import Navtop from "../navtop/Navtop";
// import Form from "react-bootstrap/Form";
import Footer from "../footer/Footer";
import "./contact.css";
const contactData = [
  {
    id: 1,
    logo: AiOutlineMail,
    name: "Email",
    data: "Studentraysglobal@gmail.com",
    datalink: "mailto:Studentraysglobal@gmail.com",
  },
  // {
  //   id: 2,
  //   logo: FiTwitter,
  //   name: "Twitter",
  //   data: "@PradumKumardub1",
  //   datalink: "https://www.twitter.com/intent/tweet?screen_name=Pradum12613076",
  // },
  {
    id: 3,
    logo: BsWhatsapp,
    name: "WhatsApp",
    data: "+91 6385623750",
    datalink: "https://wa.me/6385623750",
  },
];

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2ix9hvk",
        "template_bid360b",
        form.current,
        "YxeoYNZzXmlUvA4Es"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <div className="front" style={{ width: "100%" }}>
        {/* <div> */}
        <Navtop />
        <Navb />
        {/* </div> */}
      </div>

      <section id="contact" style={{ "padding-top": "0rem" }}>
        <h1 className="contactHead">Get in Touch</h1>
        {/* <h5>Get in Touch</h5> */}
        <div className="contact_container container">
          <div className="contact_options">
            {contactData &&
              contactData.map((allCard, index) => (
                <article key={index} className="contact_option">
                  <allCard.logo className="contact_option_icon" />
                  <h4>{allCard.name}</h4>
                  <h5>{allCard.data}</h5>
                  <a
                    href={allCard.datalink}
                    target="_blank"
                    rel="noreferrer"
                    className="btn"
                  >
                    Send a message
                  </a>
                </article>
              ))}
          </div>
          <form ref={form} className="contactForm" onSubmit={sendEmail}>
            <input
              type="text"
              name="name"
              placeholder="Your Full Name"
              className="black"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
            />
            <input
              type="text"
              name="mobile"
              placeholder="Your Mobile no."
              className="black"
              required
            />
            <textarea
              name="message"
              rows="7"
              placeholder="Your Message"
              className="black"
              required
            ></textarea>
            <button type="submit" className="btn btn-primary">
              Send Message
            </button>
          </form>
        </div>

        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.04130897334835!2d80.19271584727458!3d12.929500050443679!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d496000f6e9%3A0x4fef543466ccb120!2sE%20Block%20Purva%20Windermere!5e0!3m2!1sen!2sin!4v1724669775397!5m2!1sen!2sin"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
