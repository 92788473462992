import "./navbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
// import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useState } from "react";
// import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DropDown from "./DropDown";
const Navbar = () => {
  // const { dispatch } = useContext(DarkModeContext);
  const [selectedValue, setSelectedValue] = useState(); // Set the default value here
  const [selectedPersonValue, setSelectedPersonValue] = useState(); // Set the default value here
  const [selectedsectionValue, setSelectedsectionValue] = useState(); // Set the default value here

  const navigate = useNavigate();

  // const handleSelectChangePerson = (event) => {
  //   const svalue = event.target.value;
  //   setSelectedPersonValue(svalue);
  //   console.log("Selected value: ", svalue);
  // };
  // const handleSelectChange = (event) => {
  //   const value = event.target.value;
  //   setSelectedValue(value);
  //   console.log("Selected value: ", value);
  // };
  // const handleSelectChangesection = (event) => {
  //   const svalue = event.target.value;
  //   setSelectedsectionValue(svalue);
  //   console.log("Selected value: ", svalue);
  // };
  // const handleSubmit = () => {
  //   console.log("selectedPersonValue: ", selectedPersonValue);
  //   console.log("selectedsectionValue: ", selectedsectionValue);
  //   console.log("selectedValue: ", selectedValue);
  //   if (selectedPersonValue === "Student") {
  //     const baseUrl = window.location.origin;
  //     const urlToNavigate = `${baseUrl}/students/${selectedsectionValue}/${selectedValue}`;
  //     window.location.href = urlToNavigate;
  //   } else if (selectedPersonValue === "Teacher") {
  //     const baseUrl = window.location.origin;
  //     const urlToNavigate = `${baseUrl}/teachers/${selectedsectionValue}/${selectedValue}`;
  //     window.location.href = urlToNavigate;
  //   } else if (selectedPersonValue === "Counsellor") {
  //     const baseUrl = window.location.origin;
  //     const urlToNavigate = `${baseUrl}/counsellors/${selectedsectionValue}/${selectedValue}`;
  //     window.location.href = urlToNavigate;
  //   }
  // };

  return (
    <div className="navbarc">
      <div className="wrapper">
      <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div>
        {/* <div className="search">
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon />
        </div> */}

        {/* dropdown Here */}

        {/* <div>
          <label>
            Preson:
            <select
              name="person"
              onChange={handleSelectChangePerson}
              value={selectedValue}
            >
              <option value={null}>None</option>

              <option value="Student">Student</option>
              <option value="Teacher">Teacher</option>
              <option value="Counsellor">Counsellor</option>
            </select>
          </label>
          <label>
            <select
              name="detail"
              onChange={handleSelectChangesection}
              value={selectedValue}
            >
              <option value={null}>None</option>

              <option value="6">6th std</option>
              <option value="7">7th std</option>
              <option value="8">8th std</option>
              <option value="9">9th std</option>
              <option value="10">10th std</option>
              <option value="11">11th std</option>
              <option value="12">12th std</option>
            </select>
          </label>
          <label>
            <select
              name="section"
              onChange={handleSelectChange}
              value={selectedValue}
            >
              <option value={null}>None</option>
              <option value="A">Section A</option>
              <option value="B">Section B</option>
              <option value="C">Section C</option>
            </select>
          </label>
          <button type="submit" onClick={handleSubmit}>
            Go
          </button>
        </div> */}
        <div className="items">
          {/* <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div> */}
          {/* <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div> */}
          {/* <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div> */}
          <div className="item">
            <img
              src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="avatar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
