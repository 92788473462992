import React, { useState, useEffect } from "react";
import "./Quest.css";

function Quest(props) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(5).fill(""));
  const [questions, setQuestions] = useState(props.myQuestion);
  const [totalScore, setTotalScore] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const currentQuestion = questions[currentQuestionIndex];
  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(5).fill(""));
    setTotalScore(0);
    setSubmitted(false);
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     setShouldRefresh(true);
  //     console.log("This log message was written after 5 seconds.");
  //   }, 2000);
  //   console.log("Component mounted");
  // }, []);

  const handleNextClick = () => {
    const currentQuestion = questions[currentQuestionIndex];

    if (answers[currentQuestionIndex] !== "") {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        calculateTotalScore();
        setSubmitted(true);
      }
    }
  };

  const handleOptionChange = (e) => {
    const { value } = e.target;
    answers[currentQuestionIndex] = value;
    setAnswers([...answers]);
  };

  const calculateTotalScore = () => {
    let newTotalScore = 0;

    for (let i = 0; i < answers.length; i++) {
      const selectedOption = answers[i];
      const question = questions[i];
      if (selectedOption === question.correctAnswer) {
        newTotalScore += 1;
      }
    }

    setTotalScore(newTotalScore);
  };

  const isButtonDisabled = answers[currentQuestionIndex] === "";

  return (
    <div>
      <div className="Qtop">
        {currentQuestion && (
          <div>
            <p>{currentQuestion.question}</p>
            <ul>
              {currentQuestion.options.map((option, index) => (
                <li className="inpitli" key={index}>
                  <label>
                    <input
                      className="inpitForm"
                      type="radio"
                      name="options"
                      value={option}
                      checked={answers[currentQuestionIndex] === option}
                      onChange={handleOptionChange}
                      required // Make input required
                    />
                    {option}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        )}

        {submitted ? (
          <div>
            <p className="submitResult">You have scored: {totalScore}</p>
            <p className="submitResult">You can try again</p>

            <button className="nextButton" onClick={resetQuiz}>
            {/* <button className="nextButton" onClick={() => window.location.reload()}> */}
              Try Again
            </button>
          </div>
        ) : (
          <button className="nextButton" onClick={handleNextClick} disabled={isButtonDisabled}>
            {currentQuestionIndex === questions.length - 1 ? "Submit" : "Next"}
          </button>
        )}
      </div>
    </div>
  );
}

export default Quest;
