// import "./studyTable.css";

// import React, { useRef, useEffect, useState } from 'react';

// const VideoPlayer = ({ videoSrc }) => {
//   const videoRef = useRef(null);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [currentTime, setCurrentTime] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (isPlaying) {
//         setCurrentTime(videoRef.current.currentTime);
//       }
//     }, 1000);

//     videoRef.current.addEventListener('play', () => {
//       setIsPlaying(true);
//     });

//     videoRef.current.addEventListener('pause', () => {
//       setIsPlaying(false);
//     });

//     videoRef.current.addEventListener('ended', () => {
//       setIsPlaying(false);
//       clearInterval(interval);
//     });
//     const videoElement = videoRef.current;
//     if (videoElement) {
//       videoElement.controls = true;
//       videoElement.controlsList = 'nodownload'; // Disable the download button
//       videoElement.oncontextmenu = (e) => e.preventDefault(); // Disable right-click context menu
//     }
//     return () => {
//       clearInterval(interval);
//     };
//   }, [isPlaying]);
 
//   return (
//     <div>
//       <div>
//         <video ref={videoRef} className="videoControl" controls  src={videoSrc} />
//       </div>
//       <div>
//         <p>{isPlaying ? 'Playing' : 'Paused'}</p>
//         <p>Current Time: {currentTime.toFixed(2)} seconds</p>
//       </div>
//     </div>
//   );
// };

// export default VideoPlayer;
import video from "./video.mp4";
import React, { Component } from 'react';

import Button from "react-bootstrap/Button";

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoDuration: 0,
      currentTime: 0,
      isPlaying: false,
      viewTime: 0,
    };
    this.startTime = 0;
  }

  componentDidMount() {
    this.video = this.refs.video;
    this.video.addEventListener('loadedmetadata', this.handleVideoMetadata);
    this.video.addEventListener('timeupdate', this.handleTimeUpdate);
    this.video.addEventListener('play', this.handlePlay);
    this.video.addEventListener('pause', this.handlePause);
  }

  handleVideoMetadata = () => {
    this.setState({ videoDuration: this.video.duration });
  }

  handleTimeUpdate = () => {
    this.setState({ currentTime: this.video.currentTime });
  }

  handlePlay = () => {
    this.startTime = new Date().getTime();
    this.setState({ isPlaying: true });
  }

  handlePause = () => {
    if (this.state.isPlaying) {
      const currentTime = new Date().getTime();
      const elapsedTime = (currentTime - this.startTime) / 1000;
      this.setState((prevState) => ({
        isPlaying: false,
        viewTime: prevState.viewTime + elapsedTime,
      }));
    }
  }

  formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
  handleQuestion = () => {
    this.props.setisFalse(false);
  };
  render() {
    const { setisFalse } = this.props;
    return (
      <div>
        <video style={{ width:'50rem' }} ref="video" controls>
          <source src={video} type="video/mp4" />
        </video>
        <p>Total Duration: {this.formatTime(this.state.videoDuration)}</p>
        <p>Current Time: {this.formatTime(this.state.currentTime)}</p>
        <p>Total View Time: {this.formatTime(this.state.viewTime)}</p>
        <Button onClick={this.handleQuestion} variant="outline-primary">Next</Button>
      </div>
    );
  }
}

export default VideoPlayer;
