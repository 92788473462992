import React from "react";
import useFetch from "../../../hooks/useFetch";
import { Link } from "react-router-dom";
const Subchapter = (props) => {
  console.log("dataid", props.ied);
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/details/${props.ied}`);
  console.log("data", data);
//   const topicData = data.miniTopics;
//   console.log("topicData", topicData);
  return (
    <div className="oneChapter">
      <img
        className="iconChapter"
        src="	https://df0b18phdhzpx.cloudfront.net/journeys/production/24967.png?1586531329"
        alt="icon"
      />
      <div className="singleChapterName">{data.SubTopic}</div>
      <Link to={`/dashboard/topic/${props.ied}`}>
        <button className="butn">Start</button>
      </Link>
    </div>
  );
};

export default Subchapter;
