import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Navb from "../../assets/components/nav/Navb";
import Navtop from "../../assets/components/navtop/Navtop";
import Footer from "../../assets/components/footer/Footer";
import "./login.css";
import { Link } from "react-router-dom";
const Counsellorlogin = () => {
  const [credentials, setCredentials] = useState({
    username: undefined,
    password: undefined,
  });

  const { loading, error, dispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post(
        "https://api.studentrays.com/api/auth/clogin",
        credentials
      );
      console.log("resData", res.data);
      if (res.data.isCounsellor) {
        dispatch({ type: "LOGIN_SUCCESS", payload: res.data.details });

        navigate("/counsellordashboard");
      } else {
        dispatch({
          type: "LOGIN_FAILURE",
          payload: { message: "You are not allowed!" },
        });
      }
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
    }
  };

  return (
    <>
      <div>
        <Navtop />
        <Navb />
      </div>
      <div className="login">
        <div className="lContainer">
          <h3>Counsellor</h3>
          <input
            type="text"
            placeholder="username"
            id="username"
            onChange={handleChange}
            className="lInput"
          />
          <input
            type="password"
            placeholder="password"
            id="password"
            onChange={handleChange}
            className="lInput"
          />
          <button disabled={loading} onClick={handleClick} className="lButton">
            Login
          </button>
          {error && <span>{error.message}</span>}
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Counsellorlogin;
