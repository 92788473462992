import "./datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows } from "./datatablesource";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import axios from "axios";

const UserDatatable = ({ columns, data, type }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/")[1];
  const paths = "singleuser";
  const { user } = useContext(AuthContext);
  // console.log("teacheruser", user.teacherSection);
  const [Links, setLinks] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  // console.log("Datatable Data", data);
  // console.log(data);

  const handleClick = async (myid) => {
    // console.log("myid", myid);
    const code = await getCodeFromUrl();
    navigate("/counsellordashboard/singleuser", {
      state: { id: myid, code: code },
    });
  };

  const handleSelectionChange = (selectionModel) => {
    // Get the IDs of the selected rows
    const selectedIds = selectionModel.map((model) => model);
    setSelectedIds(selectedIds);
    console.log("Selected IDs:", selectedIds);
    // console.log("Selected selectionModel:", selectionModel);
  };
  // useEffect(() => {
  //   console.log("selectedIds",selectedIds);
  // }, [selectedIds])

  const handlebatchmeetzoomClick = (requestData, batchDates) => {
    // Define the data to be sent in the request body
    // console.log(BatchDates, 277);
    const requestDatas = {
      batch: requestData.batch,
      startDate: requestData.startDate,
      endDate: requestData.endDate,
      startTime: requestData.startTime,
      endTime: requestData.endTime,
      selectedDays: requestData.selectedDays,
      code: requestData.code,
      BatchDates: batchDates,
    };
    // code: getCodeFromUrl(),
    // const requestDatas = {
    //   batch: "cbse8scib",
    //   startDate: "2024-05-03",
    //   endDate: "2024-05-29",
    //   startTime: "18:00",
    //   endTime: "19:00",
    //   selectedDays: ["monday", "thursday"],
    //   code: "D03a6VqA3ZDqWT2xtXqRlmfVCXhCooDVw",
    //   BatchDates: [
    //     "2024-05-06",
    //     "2024-05-09",
    //     "2024-05-13",
    //     "2024-05-16",
    //     "2024-05-20",
    //     "2024-05-23",
    //     "2024-05-27",
    //   ],
    // };

    console.log(batchDates, 277);
    console.log(requestDatas, 3);

    // Send a POST request to the specified URL with the data in the request body
    axios
      .post("https://api.studentrays.com/api/auth/zoom/callback_Counc", requestDatas)
      .then((response) => {
        console.log("Response:", response);
        console.log("Response data:", response.data);
        console.log("batch: requestData.batch,", requestData.batch);
        const meetings = response.data;
        // setLinks(meetings);
        setLinks(response.data);
        handleteacherSelectChange("councellor", user._id, response.data);
        // handleteacherSelectChange(data.username, user._id, response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors if needed
      });
  };
  const handleteacherSelectChange = async (name, id, links) => {
    console.log("name, id", name, id);
    console.log("setLinks", Links);
    console.log("links", links);
    // console.log("startDate", startDate);
    // console.log("endDate", endDate);
    // console.log("startTime", startTime);
    // console.log("endTime", endTime);
    let storedData = localStorage.getItem("request");
    console.log("storedData", storedData);
    // console.log("selectedWeekdays", selectedWeekdays);
    const parsedStoredData = storedData ? JSON.parse(storedData) : {};

    // Destructure properties from parsedStoredData with default values or undefined
    const {
      batch = undefined,
      startDate = undefined,
      endDate = undefined,
      startTime = undefined,
      endTime = undefined,
      selectedDays = [],
    } = parsedStoredData;
    // Construct dataToSend object
    const dataToSend = {
      Links: links,
      startDate,
      endDate,
      startTime,
      endTime,
      selectedDays,
      id,
      studentid: selectedIds,
      name,
      batch,
    };
    console.log("dataToSend", dataToSend);
    const response = await axios.post(
      "https://api.studentrays.com/api/calender/counc_multidata",
      dataToSend
    );
    console.log(response, 351);
    window.alert(
      `Meeting Created SuccessFully for ${startDate} from ${startTime} `
    );
    // window.location.reload();
    // setSelectedTeacherId(id);
    // localStorage.setItem("request":"");
  };

  const handlemeetzoomClick = (requestData) => {
    const requestDatas = {
      batch: requestData.batch,
      startDate: requestData.startDate,
      endDate: requestData.endDate,
      startTime: requestData.startTime,
      endTime: requestData.endTime,
      selectedDays: requestData.selectedDays,
      code: requestData.code,
    };
    // const requestDatas = {
    //     batch: "cbse8scib",
    //     startDate: "2024-05-03",
    //     endDate: "2024-05-29",
    //     startTime: "18:00",
    //     endTime: "19:00",
    //     selectedDays: ["monday", "thursday"],
    //     code: "g9y5mMZ23U6F0Q7Sd1_TFa5-fwTY20QdA",
    //   };
    console.log("request  Datas", requestDatas);
    // Send a POST request to the specified URL with the data in the request body
    axios
      .post(
        "https://api.studentrays.com/api/generate-zoom-meeting-dates",
        requestDatas
      )
      .then((response) => {
        // setBatchDates(["2024-06-12"]);
        // setBatchDates(response.data.startDateTimeList);
        console.log("Response:", response.data.startDateTimeList);
        // console.log(BatchDates, 277);
        // handlebatchmeetzoomClick(requestData, ["2024-06-12"]);
        handlebatchmeetzoomClick(requestData, response.data.startDateTimeList);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors if needed
      });
  };
  const getCodeFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    // const { code } = location.state || {};
    console.log("code", code);
    return code;
  };
  const local = async () => {
    let storedData = localStorage.getItem("request");
    console.log("storedData", storedData);
    if (storedData) {
      storedData = JSON.parse(storedData);
      storedData.code = await getCodeFromUrl();
      await handlemeetzoomClick(storedData);
      // fetchData(storedData.batch);
    }
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <button
              className="viewButton"
              onClick={() => handleClick(params.row._id)}
            >
              View
            </button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="datatable">
        <div className="datatableTitle">{type}</div>
        {selectedIds.length > 0 && (
          <button className="btn btn-primary" onClick={local}>
            Create multiple meeting
          </button>
        )}
        <DataGrid
          className="datagrid"
          rows={data}
          columns={columns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
          getRowId={(row) => row._id}
          onRowSelectionModelChange={(newSelectionModel) => {
            handleSelectionChange(newSelectionModel);
          }}
        />
      </div>
    </>
  );
};

export default UserDatatable;
