import React from "react";
import Navb from "../../assets/components/nav/Navb";
import Navtop from "../../assets/components/navtop/Navtop";
import "./content.css"; // import Questionnaire from "./Questionnaire";
import VideoPage from "./VideoPage";
import Quest from "./Quest";
import Footer from "../../assets/components/footer/Footer";
import ScrollButton from "../../assets/components/scroll/Scroll";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  gr6sci,
  gr6mat,
  gr7sci,
  gr7mat,
  gr8sci,
  gr8mat,
  gr9sci,
  gr9mat,
  gr10sci,
  gr10mat,
  icse6sci,
  icse6mat,
  icse7sci,
  icse7mat,
  icse8sci,
  icse8mat,
  icse9sci,
  icse9mat,
  icse10sci,
  icse10mat,
} from "./ContentData";
import { useContext, useState, useEffect } from "react";
const Content = () => {
  const location = useLocation();
  const gradepath = location.pathname.split("/")[2];
  // const subjectpath = location.pathname.split("/")[3];
  const [datas, setdatas] = useState("");
  console.log("seethisData", gradepath);
  // console.log("see", gr1sci);
  // console.log("seedata", gradepath.data);
  // console.log("seelist", gradepath.list);
  useEffect(() => {
    let content;
    switch (gradepath) {
      case "gr6sci":
        content = gr6sci;
        // console.log("gr1sci", gr1sci);
        // content="gr1sci";
        break;

      case "gr6mat":
        // console.log("gr1mat");
        // content = "gr1mat";

        // console.log("see1data", gr1mat.data);
        // console.log("see1list", gr1mat.list);
        content = gr6mat;
        break;

      case "gr7sci":
        // console.log("gr2sci");
        // content="gr2sci";
        content = gr7sci;
        break;

      case "gr7mat":
        // console.log("gr2mat");
        content = gr7mat;
        // content="gr2mat";
        break;
      case "gr8sci":
        // console.log("gr2sci");
        // content="gr2sci";
        content = gr8sci;
        break;

      case "gr8mat":
        // console.log("gr2mat");
        content = gr8mat;
        // content="gr2mat";
        break;
      case "gr9sci":
        // console.log("gr2sci");
        // content="gr2sci";
        content = gr9sci;
        break;

      case "gr9mat":
        // console.log("gr2mat");
        content = gr9mat;
        // content="gr2mat";
        break;
      case "gr10sci":
        // console.log("gr2sci");
        // content="gr2sci";
        content = gr10sci;
        break;

      case "gr10mat":
        // console.log("gr2mat");
        content = gr10mat;
        // content="gr2mat";
        break;

      case "icse6sci":
        content = icse6sci;
        break;
      case "icse6mat":
        content = icse6mat;
        break;
      case "icse7sci":
        content = icse7sci;
        break;
      case "icse7mat":
        content = icse7mat;
        break;
      case "icse8sci":
        content = icse8sci;
        break;
      case "icse8mat":
        content = icse8mat;
        break;
      case "icse9sci":
        content = icse9sci;
        break;
      case "icse9mat":
        content = icse9mat;
        break;
      case "icse10sci":
        content = icse10sci;
        break;
      case "icse10mat":
        content = icse10mat;
        break;

      default:
        content = "default";
        break;
    }
    setdatas(content); // Set datas using setDatas
  }, [gradepath]);
  console.log("daque", datas.questions);
  console.log("da", datas.data);
  console.log("dalist", datas.list);
  const shuffledQuestions = datas.questions
    ? [...datas.questions].sort(() => Math.random() - 0.5)
    : [];

  // Select the first 5 questions
  const selectedQuestions = shuffledQuestions.slice(0, 5);
  console.log(datas, 151);
  const redirectToRegister = () => {
    let course = "";
    let grade = "";

    // Extract grade from the gradepath
    const gradePrefix = gradepath.substring(0, 2);
    const gradeNumericPart = gradepath.match(/\d+/);

    // Determine course based on gradepath
    switch (gradePrefix.toLowerCase()) {
      case "gr": // CBSE
        course = `CBSE`;
        grade = `${gradeNumericPart}`;
        break;
      case "ic": // ICSE
        course = `ICSE`;
        grade = `${gradeNumericPart}`;
        break;
      default:
        // Handle other cases if needed
        break;
    }

    // Construct the registration URL with query parameters
    const registerURL = `/register?course=${course}&grade=${grade}`;

    // Redirect to register URL
    window.location.href = registerURL;
  };

  return (
    <>
      <div>
        <Navtop />
        <Navb />
      </div>
      <header className="contentHeader">
        {datas && <h1 className="contentHeaderTopic">{datas.data}</h1>}
        {/* NCERT Solutions for Class 10 Maths */}
        <p className="contentHeaderContent">Content to be covered</p>
        <ul>
          {datas &&
            datas.list.map((item, index) => (
              <li className="contentLi" key={index}>
                {item.abelableContent}
              </li>
            ))}
        </ul>
      </header>

      {/* <div className="container"> */}
      <div id="content">
        {/* <VideoPage /> */}
        <div className="data">
          <h1 className="tryQuiz">Try Quiz</h1>
          {/* {datas && <Quest myQuestion={datas.questions} />} */}
          {datas && <Quest myQuestion={selectedQuestions} />}
          <button className="registerButton" onClick={redirectToRegister}>
            Book Free Demo
          </button>
        </div>
      </div>

      {/* <div className="understandArea">
          <div className="understandAreaTopic">
            How to make student understand the subject without memorization
          </div>
          <p className="centerone">
            Teaching in a way that encourages understanding rather than rote
            memorization is essential for fostering critical thinking,
            problem-solving skills, and long-term retention of knowledge. Here
            in Rays we help students understand concepts without relying on
            memorization
          </p>

          <div className="understandAreaFlex">
            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">Active Learning</div>
              <p className="understandAreaCardText">
                Encourage active participation in the learning process. This can
                include group discussions, debates, problem-solving exercises,
                and hands-on activities. Active learning engages students in the
                material and helps them understand concepts through application
              </p>
            </div>

            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">
                Real-Life Connections
              </div>
              <p className="understandAreaCardText">
                Relate the content to real-life situations and examples. When
                students can see how a concept applies in their daily lives,
                they are more likely to understand and remember it. This
                approach also makes learning more meaningful and relevant
              </p>
            </div>

            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">Visual Aids</div>
              <p className="understandAreaCardText">
                Utilize visual aids like diagrams, charts, graphs, and videos to
                illustrate complex ideas. Visual representations can simplify
                abstract concepts and make them easier to grasp
              </p>
            </div>

            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">
                Analogies and Metaphors
              </div>
              <p className="understandAreaCardText">
                Use analogies and metaphors to explain difficult concepts by
                comparing them to something more familiar. These comparisons can
                bridge the gap between the unknown and the known, making it
                easier for students to understand
              </p>
            </div>

            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">Hands-On Learning</div>
              <p className="understandAreaCardText">
                Incorporate hands-on experiences, experiments, and simulations
                whenever possible. These activities allow students to explore
                and interact with concepts directly, deepening their
                understanding
              </p>
            </div>

            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">
                Socratic Questioning
              </div>
              <p className="understandAreaCardText">
                Ask open-ended questions that encourage critical thinking and
                discussion. Instead of providing answers, guide students in
                exploring and discovering solutions on their own
              </p>
            </div>

            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">Concept Mapping</div>
              <p className="understandAreaCardText">
                Encourage students to create concept maps or mind maps to
                visually organize information and show the relationships between
                different ideas. This helps them see the bigger picture and how
                concepts connect
              </p>
            </div>

            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">Storytelling</div>
              <p className="understandAreaCardText">
                Present information as a narrative or story. Stories have a
                natural flow and structure that can help students remember and
                understand complex information
              </p>
            </div>

            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">
                Problem-Based Learning
              </div>
              <p className="understandAreaCardText">
                Present students with real-world problems or scenarios and guide
                them through the process of solving these problems. This
                approach fosters critical thinking and problem-solving skills
              </p>
            </div>

            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">Peer Teaching</div>
              <p className="understandAreaCardText">
                Allow students to teach each other. When students explain
                concepts to their peers, it reinforces their own understanding
                and encourages collaboration
              </p>
            </div>

            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">
                Flexibility in Assessment
              </div>
              <p className="understandAreaCardText">
                Move away from traditional memorization-based exams and
                assessments. Use a variety of assessment methods, such as
                projects, presentations, and portfolios, that assess students'
                understanding and application of concepts
              </p>
            </div>

            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">Personalization</div>
              <p className="understandAreaCardText">
                Recognize that every student is different, and their learning
                preferences may vary. Adapt your teaching methods to accommodate
                different learning styles and preferences
              </p>
            </div>

            <div className="understandAreaCard">
              <div className="understandAreaCardTopic">
                Reflective Practices
              </div>
              <p className="understandAreaCardText">
                Encourage students to reflect on their learning experiences. Ask
                them to write journals or participate in discussions about what
                they've learned, how they learned it, and what challenges they
                faced
              </p>
            </div>
          </div>
        </div> */}
      {/* </div> */}
      <ScrollButton />
      <Footer />
    </>
    // </>
  );
};

export default Content;
