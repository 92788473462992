// TeacherCalender for Counceller
import React, { useState, useEffect, useContext } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import useFetch from "../../../hooks/useFetch";
import "./calender.css";
import { useLocation, useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

const CounsellorTCalender = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const { data: userdata } = useFetch(`https://api.studentrays.com/api/users/${id}`);

  //   let userId = "";
  //   let batch = "";
  //   if (userdata) {
  //     userId = userdata._id;
  //     batch = userdata?.batch?.[0] ?? "";
  //   }

  const { data: calender } = useFetch(
    `https://api.studentrays.com/api/calender/${id}`
  );

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (calender && calender.length > 0) {
      const exampleEvent = calender.map((event, index) => {
        const {
          Date: eventDate,
          startTime,
          endTime,
          joinUrl,
          teacherUrl,
          batch,
        } = event;

        const [year, month, day] = eventDate.split("-").map(Number);
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);

        const start = new Date(year, month - 1, day, startHour, startMinute);
        const end = new Date(year, month - 1, day, endHour, endMinute);
        if (userdata.isCounsellor === true) {
          return {
            id: index + 1,
            title: `${batch}`, // Use batch ID in the title
            start,
            end,
            url: teacherUrl, // Use teacherUrl for other batches
            batch, // Include the batch information
          };
        } else {
          if (batch === "councellor") {
            return {
              id: index + 1,
              title: `${batch}`, // Use batch ID in the title
              start,
              end,
              url: joinUrl, // Use joinUrl for councellor batch
              batch, // Include the batch information
            };
          } else {
            return {
              id: index + 1,
              title: `${batch}`, // Use batch ID in the title
              start,
              end,
              url: teacherUrl, // Use teacherUrl for other batches
              batch, // Include the batch information
            };
          }
        }
        // return {
        //   id: index + 1,
        //   title: `${batch}`, // Use batch ID in the title
        //   start,
        //   end,
        //   url: teacherUrl,
        //   batch, // Include the batch information
        // };
      });

      setEvents(exampleEvent);
    }
  }, [calender]);

  // Coloring Here
  const eventStyleGetter = (event, start, end, isSelected) => {
    const batchColors = [
      "blue",
      "red",
      "green",
      "purple",
      "orange",
      "pink",
      "cyan",
      "brown",
      "yellow",
      "magenta",
    ];

    const batchIndex = events.findIndex((e) => e.batch === event.batch);
    const color = batchColors[batchIndex % batchColors.length];

    const style = {
      backgroundColor: color,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "none",
      display: "block",
    };
    return { style };
  };

  const handleSelectSlot = (slotInfo) => {
    const selectedDate = moment(slotInfo.start).startOf("day");
    const today = moment().startOf("day");

    if (!selectedDate.isSame(today)) {
      alert("Only today's date is selectable.");
    } else {
      // Handle selection for today's date if needed
    }
  };

  const handleSelectEvent = (event, e) => {
    e.preventDefault();
    const eventDate = moment(event.start).startOf("day");
    const today = moment().startOf("day");

    if (eventDate.isSame(today)) {
      window.open(event.url, "_blank").focus();
    } else {
      alert("Only today's events are clickable.");
    }
  };

  const dateCellWrapper = ({ value, children }) => {
    const date = moment(value).startOf("day");
    const today = moment().startOf("day");

    return React.cloneElement(React.Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: date.isSame(today) ? "white" : "#f0f0f0",
        pointerEvents: date.isSame(today) ? "auto" : "none",
      },
    });
  };
  console.log("events", events);
  return (
    <div>
      {events && (
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable
          eventPropGetter={eventStyleGetter}
          views={["month", "week", "day"]}
          defaultView="week"
          popup
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          components={{ dateCellWrapper }}
        />
      )}
    </div>
  );
};

export default CounsellorTCalender;
