import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./dashNavbar.css"
function DashNavbar() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  // const { dispatch } = useContext(DarkModeContext);
  // console.log("compcamp",user.comptopics);
  // console.log("compegrade",user.grade);
  // console.log("compe_id",user._id);

  const handleLogout = async () => {
    // dispatch({ type: "LOGOUT_START" });
    try {
      localStorage.removeItem("user");
      const res = await axios.post("https://api.studentrays.com/api/auth/logout"); // Send a request to your backend logout endpoint
      if (res.status === 200) {
        // dispatch({ type: "LOGOUT_SUCCESS" });
        navigate("/");
      } else {
        // dispatch({ type: "LOGOUT_FAILURE" });
        console.log("Logout Failed");
      }
    } catch (err) {
      // dispatch({ type: "LOGOUT_FAILURE" });
      console.log(err);
    }
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      {/* <Container> */}
      <Navbar.Brand href="/teacherdashboard/">Rays</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#link">Link</Nav.Link>
          <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown>
          {/* </Nav>
      </Navbar.Collapse> */}
      <div className="allflex">
          {/* <Form inline style={{ padding: 6, width: "25rem" }} >
            <Row>
              <Col xs="auto">
                <Form.Control
                  type="text"
                  placeholder="Search"
                  className=" mr-sm-2"
                />
              </Col>
              <Col xs="auto">
                <Button type="submit">Submit</Button>
              </Col>
            </Row>
          </Form> */}
          <Navbar.Text>
            Teacher: <a href="login">{user.username}</a>
            {/* Signed in as: <a href="login">{{user.username}}</a> */}
            <Button onClick={handleLogout}>Logout</Button>
          </Navbar.Text>
          </div>
        </Nav>
      </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
}

export default DashNavbar;
