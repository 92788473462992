// import React, { useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import Navtop from '../../assets/components/navtop/Navtop';
// import Navb from '../../assets/components/nav/Navb';
// import Footer from '../../assets/components/footer/Footer';
// import "./login.css";

// const ForgotPassword = () => {
//   const [email, setEmail] = useState('');
//   const [code, setCode] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [step, setStep] = useState(1);
//   const [error, setError] = useState('');
//   const navigate = useNavigate();

//   const handleSendCode = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.post('https://api.studentrays.com/api/auth/forgot-password', { email });
//       setStep(2);
//     } catch (err) {
//       setError(err.response.data.message);
//     }
//   };

//   const handleVerifyCode = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.post('https://api.studentrays.com/api/auth/verify-code', { email, code });
//       setStep(3);
//     } catch (err) {
//       setError(err.response.data.message);
//     }
//   };

//   const handleResetPassword = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.post('https://api.studentrays.com/api/auth/reset-password', { email, code, newPassword });
//       navigate('/login');
//     } catch (err) {
//       setError(err.response.data.message);
//     }
//   };

//   return (
//     <>
//       <Navtop />
//       <Navb />
//       <div className="forgotPassword">
//         <div className="fpContainer">
//           <h3>Forgot Password</h3>
//           {step === 1 && (
//             <form onSubmit={handleSendCode}>
//               <input
//                 type="email"
//                 placeholder="Enter your email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//               />
//               <button type="submit">Send Code</button>
//             </form>
//           )}
//           {step === 2 && (
//             <form onSubmit={handleVerifyCode}>
//               <input
//                 type="text"
//                 placeholder="Enter verification code"
//                 value={code}
//                 onChange={(e) => setCode(e.target.value)}
//                 required
//               />
//               <button type="submit">Verify Code</button>
//             </form>
//           )}
//           {step === 3 && (
//             <form onSubmit={handleResetPassword}>
//               <input
//                 type="password"
//                 placeholder="Enter new password"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//                 required
//               />
//               <button type="submit">Reset Password</button>
//             </form>
//           )}
//           {error && <span className="error">{error}</span>}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default ForgotPassword;
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navtop from '../../assets/components/navtop/Navtop';
import Navb from '../../assets/components/nav/Navb';
import Footer from '../../assets/components/footer/Footer';
import "./login.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSendCode = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const response = await axios.post('https://api.studentrays.com/api/auth/forgot-password', { email });
      setSuccess(response.data.message);
      setStep(2);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const response = await axios.post('https://api.studentrays.com/api/auth/verify-code', { email, code });
      setSuccess(response.data.message);
      setStep(3);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const response = await axios.post('https://api.studentrays.com/api/auth/reset-password', { email, code, newPassword });
      setSuccess(response.data.message);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <>
      <Navtop />
      <Navb />
      <div className="forgotPassword">
        <div className="fpContainer">
          <h3>Forgot Password</h3>
          {step === 1 && (
            <form onSubmit={handleSendCode}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit">Send Code</button>
            </form>
          )}
          {step === 2 && (
            <form onSubmit={handleVerifyCode}>
              <input
                type="text"
                placeholder="Enter verification code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
              <button type="submit">Verify Code</button>
            </form>
          )}
          {step === 3 && (
            <form onSubmit={handleResetPassword}>
              <input
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <button type="submit">Reset Password</button>
            </form>
          )}
          {error && <span className="error">{error}</span>}
          {success && <span className="success">{success}</span>}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
