import React from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import DashNavbar from '../navbar/DashNavbar'
const DashHome = () => {
  return (
    <div>
      {/* dashHome */}
      <DashNavbar />
      <Header />
      <Footer />
    </div>
  )
}

export default DashHome
