import React, { useState } from "react";
import axios from "axios";
import EmailButton from "../EmailButton/EmailButton";
import { jsPDF } from "jspdf";

const LinkButton = ({ username, emaildata, amount, userdata }) => {
  const [success, setSuccess] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);
  const [responsedata, setResponsedata] = useState();
  const [response, setResponse] = useState();

  const handlePaymentLink = async () => {
    try {
      const responsedata = await axios.post("https://api.studentrays.com/api/create-payment-link", {
        amount: amount * 100, // amount in paise
        currency: "INR",
        receipt: `receipt_${new Date().getTime()}`,
        notes: {
          username: username,
          email: emaildata,
        },
      });
      console.log("responsedata",responsedata);
      const short_url = responsedata.data.short_url;
      setPaymentLink(short_url);
      setResponse(responsedata.data);
      setSuccess(true);
    } catch (error) {
      console.error("Error creating payment link:", error);
    }
  };

  const generatePDFReceipt = () => {
    const doc = new jsPDF({
      unit: 'mm',
      format: 'a4',
      orientation: 'portrait',
    });

    doc.setFont('helvetica', 'bold');
    doc.text('Student Rays', 90, 20);
    doc.text('Course Enrollment', 80, 25);
    doc.text('Payment Receipt', 90, 40);
    doc.setFontSize(12);

    doc.text(`Receipt No:`, 20, 60);
    doc.text(`${response?.razorpay_payment_id || "N/A"}`, 80, 60);
    doc.text(`Date:`, 20, 70);
    doc.text(`${new Date().toUTCString()}`, 80, 70);
    doc.text(`Purchased By:`, 20, 80);
    doc.text(`${username}`, 80, 80);
    doc.text(`Paid Amount:`, 20, 90);
    doc.text(`Rs. ${amount}`, 80, 90);
    // doc.text(`Phone Number:`, 20, 100);
    // doc.text(`${userdata.phone}`, 80, 100);
    doc.text(`Email:`, 20, 110);
    doc.text(`${emaildata}`, 80, 110);
    doc.text(`State:`, 20, 120);
    // doc.text(`${userdata.state}`, 80, 120);
    // doc.text(`Course:`, 20, 130);
    // doc.text(`${userdata.course}`, 80, 130);
    // doc.text(`Grade:`, 20, 140);
    // doc.text(`${userdata.grade}`, 80, 140);
    // doc.text(`Section:`, 20, 150);
    // doc.text(`${userdata.section}`, 80, 150);
    doc.setFontSize(8);
    doc.text('Please note this is an electronically generated receipt, hence does not require a signature or stamp', 45, 160);

    return doc.output('blob'); // Output as a blob for download
  };

  const email = emaildata;
  const sub = "Payment Recived Welcome to our app";
  const message = `Hi ${username},\n\nThank you for paying to our app. We hope you enjoy our services.\n\nBest regards,\nThe app team`;
  const filename = "receipt.pdf";

  const createBlob = () => {
    const receiptData = generatePDFReceipt();
    const blob = new Blob([receiptData], { type: 'application/pdf' });
    return blob;
  };

  return (
    <>
      {!paymentLink ? (
        <button onClick={handlePaymentLink} className="btn btn-primary">
          Create Payment Link
        </button>
      ) : (
        <div>
          <p>Payment Link: <a href={paymentLink} target="_blank" rel="noopener noreferrer">{paymentLink}</a></p>
{/*           
            <EmailButton
              email={email}
              sub={sub}
              message={message}
              filename={filename}
              blob={createBlob()}
            /> */}
        
        </div>
      )}
    </>
  );
};

export default LinkButton;
