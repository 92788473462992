import React from 'react'
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import { freesessionColumns } from "../datatable/datatablesource";
import Datatable from "../datatable/Datatable";
const CounsellorFreesession = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <Datatable columns={freesessionColumns}/>
      </div>
    </div>
  )
}

export default CounsellorFreesession

