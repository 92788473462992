import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./register.css";
import Navb from "../../assets/components/nav/Navb";
import Navtop from "../../assets/components/navtop/Navtop";
import Footer from "../../assets/components/footer/Footer";
import EmailButton from "../../assets/dashboard/EmailButton/EmailButton";
import { jsPDF } from "jspdf";
const Register = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    phone: "",
    course: queryParams.get("course") || "",
    grade: queryParams.get("grade") || "",
    username: "",
    password: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [filecreated, setFilecreated] = useState(false);
  const [fileContent, setFileContent] = useState();

  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      // Check if required fields are empty
      if (
        !credentials.name ||
        !credentials.email ||
        !credentials.phone ||
        !credentials.state ||
        !credentials.course ||
        !credentials.grade ||
        !credentials.username ||
        !credentials.password
      ) {
        throw new Error("Please fill in all required fields.");
      }

      const res = await axios.post("https://api.studentrays.com/api/auth/register", credentials);
      if (!res.data.isAdmin) {
        setLoading(false);
        setSuccess(true);

        const doc = new jsPDF(); // Create a new jsPDF instance
        doc.text(`User Name: ${credentials.name}`, 10, 10); // Add some text to the PDF
        doc.text(`Email: ${credentials.email}`, 10, 20); // Add some text to the PDF
        doc.text(`Password: ${credentials.password}`, 10, 30); // Add some text to the PDF

        // const myPdf = doc.save("bill.pdf"); // Save the PDF with a filename
        const pdfBlob = doc.output("blob");
        setFileContent(pdfBlob);
        // <EmailButton />
      } else {
        setLoading(false);
        setError("You are not allowed!");
      }
    } catch (err) {
      setLoading(false);
      setError(
        err.response?.data?.message || err.message || "An error occurred"
      );
    }
  };

  const handleRegisterLogin = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  const allstates = [
    { value: "1", label: "Select your State" },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Delhi", label: "Delhi" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Ladakh", label: "Ladakh" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];
  // useEffect(() => {
  //   // Check if the responsedata is not null or undefined
  //   if (success) {
  //     const doc = new jsPDF(); // Create a new jsPDF instance
  //     doc.text(`User Name: ${credentials.name}`, 10, 10); // Add some text to the PDF
  //     doc.text(`Email: ${credentials.email}`, 10, 20); // Add some text to the PDF
  //     doc.text(`Password: ${credentials.password}`, 10, 30); // Add some text to the PDF

  //     // const myPdf = doc.save("bill.pdf"); // Save the PDF with a filename
  //     const pdfBlob = doc.output("blob");
  //     // doc.output('blob').then(blob => {
  //     // });
  //     setFileContent(pdfBlob);
  //     console.log("myPdf", pdfBlob);
  //     setFilecreated(true);
  //   }
  // }, [success]); // Pass the responsedata as a dependency to the useEffect hook
  const email = credentials.email; // use the email from the credentials object
  const sub = "Welcome to our app"; // customize the subject as you wish
  const message = `Hi ${credentials.name},\n\nThank you for registering to our app. We hope you enjoy our services.\n\nBest regards,\nThe app team`; // customize the message as you wish
  // const filename = "welcome.pdf"; // customize the filename as you wish
  // const filename = myPdf; // customize the filename as you wish
  return (
    <>
      <div>
        <Navtop />
        <Navb />
      </div>
      <div className="register">
        <div className="rContainer">
          {!success && (
            <>
              <h3>Register</h3>
              <input
                type="text"
                placeholder="name"
                id="name"
                value={credentials.name}
                onChange={handleChange}
                className="rInput"
                required
              />
              <input
                type="text"
                placeholder="email"
                id="email"
                value={credentials.email}
                onChange={handleChange}
                className="rInput"
                required
              />
              <input
                type="text"
                placeholder="phone"
                id="phone"
                value={credentials.phone}
                onChange={handleChange}
                className="rInput"
                required
              />
              <select
                id="state"
                placeholder="select your State"
                value={credentials.state}
                onChange={handleChange}
                className="rInputsection"
                required
              >
                {allstates.map((option) =>
                  option.value === "1" ? (
                    <option
                      key={option.value}
                      value=""
                      default
                      disabled
                      selected
                    >
                      {option.label}
                    </option>
                  ) : (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  )
                )}
              </select>
              <select
                id="course"
                placeholder="select Course"
                value={credentials.course}
                onChange={handleChange}
                className="rInputsection"
                required
              >
                <option value="" disabled>
                  Select your Course
                </option>
                <option value="CBSE">CBSE</option>
                <option value="ICSE">ICSE</option>
              </select>

              {/* <input
                type="text"
                placeholder="Course"
                id="course"
                value={credentials.course}
                onChange={handleChange}
                className="rInput"
                required
              /> */}
              <select
                id="grade"
                placeholder="select grade"
                value={credentials.grade}
                onChange={handleChange}
                className="rInputsection"
                required
              >
                <option value="" disabled>
                  Select your grade
                </option>
                <option value="6">Grade 6</option>
                <option value="7">Grade 7</option>
                <option value="8">Grade 8</option>
                <option value="9">Grade 9</option>
                <option value="10">Grade 10</option>
              </select>
              <input
                type="text"
                placeholder="username"
                id="username"
                value={credentials.username}
                onChange={handleChange}
                className="rInput"
                required
              />
              <input
                type="password"
                placeholder="password"
                id="password"
                value={credentials.password}
                onChange={handleChange}
                className="rInput"
                required
              />
              <button
                disabled={loading}
                type="submit"
                onClick={handleRegister}
                className="rButton"
              >
                Register
              </button>
            </>
          )}
          {error && <span>{error}</span>}
          {success && <span>Successfully Registered</span>}

          {success && fileContent &&(
            <EmailButton
              email={email}
              sub={sub}
              message={message}
              filename={fileContent}
              blob = {null}
            />
          )}
          {success && (
            <button onClick={handleRegisterLogin} className="rButton">
              Go To Login
            </button>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
