import React, { useEffect, useState } from "react";
import DashNavbar from "../navbar/DashNavbar";
import useFetch from "../../../hooks/useFetch";
import Sidebar from "../sidebar/Sidebar";
import "./teacherStudentData.scss";
import { Link, useLocation } from "react-router-dom";
import ChapterDetails from "./ChapterDetails";
import { BarChart } from "@mui/x-charts/BarChart";
const TeacherStudentData = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  console.log("path", path);
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/users/${path}`);
  const { data: datas } = useFetch("https://api.studentrays.com/api/datas");
  console.log(" Data", data);
  console.log(" datas", datas);
  const filteredDatas = datas && datas.filter((one) => one.grade == data.grade);
  console.log(" filteredDatas", filteredDatas);
  //   const handleButtonClick = (subject) => {
  //     // Do something when the button is clicked
  //     console.log(`Button clicked for subject: ${subject}`);
  //     const { data: datasubject } = useFetch(`/datas/${subject}`);
  //     console.log('datasubject', datasubject);
  // };
  const [subjectname, setSubjectname] = useState("");

  const handleButtonClick = (subject) => {
    // Do something when the button is clicked
    console.log(`Button clicked for subject: ${subject}`);
    setSubjectname(subject);
  };
  const { data: datasubject } = useFetch(`https://api.studentrays.com/api/datas/find/${subjectname}`);
  console.log("datasubject", datasubject);
  const { data: chapters } = useFetch("https://api.studentrays.com/api/chapters/653bd905b303f344bf1a029f");
  console.log("chapters", chapters);
  const { chapterdetails } = datasubject;

  const chartSetting = {
    xAxis: [
      {
        label: "rainfall (mm)",
      },
    ],
    width: 500,
    height: 400,
  };
  const dataset = [
    {
      london: 59,
      paris: 57,
      newYork: 86,
      seoul: 21,
      month: "Jan",
    },
    {
      london: 50,
      paris: 52,
      newYork: 78,
      seoul: 28,
      month: "Fev",
    },
    {
      london: 47,
      paris: 53,
      newYork: 106,
      seoul: 41,
      month: "Mar",
    },
    {
      london: 54,
      paris: 56,
      newYork: 92,
      seoul: 73,
      month: "Apr",
    },
    {
      london: 57,
      paris: 69,
      newYork: 92,
      seoul: 99,
      month: "May",
    },
    {
      london: 60,
      paris: 63,
      newYork: 103,
      seoul: 144,
      month: "June",
    },
    {
      london: 59,
      paris: 60,
      newYork: 105,
      seoul: 319,
      month: "July",
    },
    {
      london: 65,
      paris: 60,
      newYork: 106,
      seoul: 249,
      month: "Aug",
    },
    {
      london: 51,
      paris: 51,
      newYork: 95,
      seoul: 131,
      month: "Sept",
    },
    {
      london: 60,
      paris: 65,
      newYork: 97,
      seoul: 55,
      month: "Oct",
    },
    {
      london: 67,
      paris: 64,
      newYork: 76,
      seoul: 48,
      month: "Nov",
    },
    {
      london: 61,
      paris: 70,
      newYork: 103,
      seoul: 25,
      month: "Dec",
    },
  ];

  const valueFormatter = (value) => `${value}mm`;

  return (
    <div>
      <div className="fixed">
        <DashNavbar />
      </div>
      <div className="teacherflex">
        <Sidebar />
        {/* <div> */}
        <div className="sidebars flexdata">
          <div className="top">
            <div className="left">
              <h1 className="title">Information</h1>
              <div className="item">
                <div className="details">
                  <h1 className="itemTitle">{data.username}</h1>
                  {/* <h1 className="itemTitle">name</h1> */}
                  <div className="detailItem">
                    <span className="itemKey">Email:</span>
                    <span className="itemValue">{data.email}</span>
                    {/* <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Laudantium fugit minima quidem ipsam numquam non, aperiam
                      voluptas, ex, perferendis quas maxime maiores! Optio porro
                      aut reprehenderit distinctio velit quod eum!
                    </p> */}
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Mobile:</span>
                    <span className="itemValue">{data.phone}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Grade:</span>
                    <span className="itemValue">{data.grade}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Section:</span>
                    <span className="itemValue">{data.section}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BarChart
            dataset={dataset}
            yAxis={[{ scaleType: "band", dataKey: "month" }]}
            series={[
              { dataKey: "seoul", label: "Seoul rainfall", valueFormatter },
            ]}
            layout="horizontal"
            {...chartSetting}
          />
          <div className="subject">
            {filteredDatas &&
              filteredDatas.map((data) => (
                <button
                  className="subjectButton"
                  key={data._id}
                  onClick={() => handleButtonClick(data._id)}
                >
                  {data.subject}
                </button>
              ))}
          </div>
          {datasubject && (
            <div>
              <span className="subjectTopic">Subject Name: </span>
              <span className="subjectname">{datasubject.subject}</span>
              <span className="space">|</span>
              <span className="subjectTopic">Chapter Count: </span>
              <span className="subjectname">{datasubject.chapterCount}</span>
            </div>
          )}
          {chapterdetails &&
            chapterdetails.map((id, index) => (
              <ChapterDetails
                key={index}
                id={id}
                compdata={data.comptopics}
                scoredata={data.scores}
              />
            ))}
          {/* {chapters && (
            <div>
              <span className="subjectTopic">Chapter Name: </span>
              <span className="subjectname">{chapters.chapterName}</span>
            </div>
          )} */}
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam
            id eius commodi temporibus natus sed, quo rem nemo repudiandae vel
            debitis dicta labore a esse cum reiciendis et at consequatur?
          </p>
        </div>
      </div>
    </div>
  );
};

export default TeacherStudentData;
