import React from 'react'
import DashHome from './dashHome/DashHome'
import "./dashHome.css";
const Dashboard = () => {
  return (
    <div className="dashbord">
    {/* <h1>Dashbord</h1> */}
      <DashHome />
    </div>
  )
}

export default Dashboard
