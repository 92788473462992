import "./single.scss";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./navbar/Navbar";
import useFetch from "../../hooks/useFetch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
const Single = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location:", location);
  const { id } = location.state || {};
  const { code } = location.state || {};
  // const [BatchDates, setBatchDates] = useState([]);
  const [Links, setLinks] = useState([]);
  console.log("id from state:", id);
  // console.log("code from state:", code);
  // const id = location.pathname.split("/")[2];
  // console.log("id", id);
  const { data, loading, error } = useFetch(
    `https://api.studentrays.com/api/users/${id}`
  );
  console.log("seeData", data);

  const handlebatchmeetzoomClick = (requestData, batchDates) => {
    // Define the data to be sent in the request body
    // console.log(BatchDates, 277);
    const requestDatas = {
      batch: requestData.batch,
      startDate: requestData.startDate,
      endDate: requestData.endDate,
      startTime: requestData.startTime,
      endTime: requestData.endTime,
      selectedDays: requestData.selectedDays,
      code: requestData.code,
      BatchDates: batchDates,
    };
    // code: getCodeFromUrl(),
    // const requestDatas = {
    //   batch: "cbse8scib",
    //   startDate: "2024-05-03",
    //   endDate: "2024-05-29",
    //   startTime: "18:00",
    //   endTime: "19:00",
    //   selectedDays: ["monday", "thursday"],
    //   code: "D03a6VqA3ZDqWT2xtXqRlmfVCXhCooDVw",
    //   BatchDates: [
    //     "2024-05-06",
    //     "2024-05-09",
    //     "2024-05-13",
    //     "2024-05-16",
    //     "2024-05-20",
    //     "2024-05-23",
    //     "2024-05-27",
    //   ],
    // };

    console.log(batchDates, 277);
    console.log(requestDatas, 3);

    // Send a POST request to the specified URL with the data in the request body
    axios
      .post("https://api.studentrays.com/api/auth/zoom/callback_Counc", requestDatas)
      .then((response) => {
        console.log("Response:", response);
        console.log("Response data:", response.data);
        console.log("batch: requestData.batch,", requestData.batch);
        const meetings = response.data;
        // setLinks(meetings);
        setLinks(response.data);
        handleteacherSelectChange(data.username, user._id, response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors if needed
      });
  };
  const handleteacherSelectChange = async (name, id, links) => {
    console.log("name, id", name, id);
    console.log("setLinks", Links);
    console.log("links", links);
    // console.log("startDate", startDate);
    // console.log("endDate", endDate);
    // console.log("startTime", startTime);
    // console.log("endTime", endTime);
    let storedData = localStorage.getItem("request");
    console.log("storedData", storedData);
    // console.log("selectedWeekdays", selectedWeekdays);
    const parsedStoredData = storedData ? JSON.parse(storedData) : {};

    // Destructure properties from parsedStoredData with default values or undefined
    const {
      batch = undefined,
      startDate = undefined,
      endDate = undefined,
      startTime = undefined,
      endTime = undefined,
      selectedDays = [],
    } = parsedStoredData;
    // Construct dataToSend object
    const dataToSend = {
      Links: links,
      startDate,
      endDate,
      startTime,
      endTime,
      selectedDays,
      id,
      studentid: data._id,
      name,
      batch,
    };
    console.log("dataToSend", dataToSend);
    const response = await axios.post(
      "https://api.studentrays.com/api/calender/counc_data",
      dataToSend
    );
    console.log(response, 351);
    window.alert("Meeting Created Sucessfully!");
    // setSelectedTeacherId(id);
    // localStorage.setItem("request":"");
  };
  const handlemeetzoomClick = (requestData) => {
    const requestDatas = {
      batch: requestData.batch,
      startDate: requestData.startDate,
      endDate: requestData.endDate,
      startTime: requestData.startTime,
      endTime: requestData.endTime,
      selectedDays: requestData.selectedDays,
      code: requestData.code,
    };
    //   const requestDatas ={
    //     "batch": "councellor",
    //     "startDate": "2024-06-21",
    //     "endDate": "2024-06-21",
    //     "startTime": "01:56",
    //     "endTime": "02:57",
    //     "selectedDays": [
    //         "friday"
    //     ],
    //     "code": requestData.code,
    // }
    // const requestDatas = {
    //     batch: "cbse8scib",
    //     startDate: "2024-05-03",
    //     endDate: "2024-05-29",
    //     startTime: "18:00",
    //     endTime: "19:00",
    //     selectedDays: ["monday", "thursday"],
    //     code: "g9y5mMZ23U6F0Q7Sd1_TFa5-fwTY20QdA",
    //   };
    console.log("request  Datas", requestDatas);
    // Send a POST request to the specified URL with the data in the request body
    axios
      .post(
        "https://api.studentrays.com/api/generate-zoom-meeting-dates",
        requestDatas
      )
      .then((response) => {
        // setBatchDates(["2024-06-12"]);
        // setBatchDates(response.data.startDateTimeList);
        console.log("Response:", response.data.startDateTimeList);
        // console.log(BatchDates, 277);
        // handlebatchmeetzoomClick(requestData, ["2024-06-12"]);
        handlebatchmeetzoomClick(requestData, response.data.startDateTimeList);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors if needed
      });
  };
  const getCodeFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    // const code = searchParams.get("code");
    const { code } = location.state || {};
    return code;
  };
  const local = async () => {
    let storedData = localStorage.getItem("request");
    console.log("storedData", storedData);
    if (storedData) {
      storedData = JSON.parse(storedData);
      storedData.code = await getCodeFromUrl();
      await handlemeetzoomClick(storedData);
      // fetchData(storedData.batch);
    }
  };
  return (
    <div className="single">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Information</h1>
            <div className="item">
              <img
                // src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                src={data.img}
                alt=""
                className="itemImg"
              />
              <div className="details">
                {/* <h1 className="itemTitle">Jane Doe</h1> */}
                <h1 className="itemTitle">{data.name}</h1>
                <div className="detailItem">
                  <span className="itemKey">Username:</span>
                  <span className="itemValue">{data.username}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{data.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{data.phone}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Grade:</span>
                  <span className="itemValue">{data.grade}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">section:</span>
                  <span className="itemValue">{data.section}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">batch:</span>
                  <span className="itemValue">{data.batch}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">course:</span>
                  <span className="itemValue">{data.course}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">payment:</span>
                  {data.payment ? (
                    <span className="itemValue">Paid</span>
                  ) : (
                    <span className="itemValue">Not Paid</span>
                  )}
                  <span className="itemValue">{data.payment}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">state:</span>
                  <span className="itemValue">{data.state}</span>
                </div>
                {data.payment ? (
                  <div className="joinClassLower">
                    <Link to={`/counsellordashboard/calender/${data._id}`}>
                      <button className="btn btn-primary">Calender</button>
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {data.isTeacher ? (
                  <div className="joinClassLower">
                    <Link to={`/counsellordashboard/tcalender/${data._id}`}>
                      <button className="btn btn-primary">View Calender</button>
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {/* {id && <p>ID: {id}</p>} */}
                {code && (
                  <button
                    className="btn btn-primary"
                    onClick={local}
                    style={{ marginTop: "10px", fontSize: "14px" }}
                  >
                    Create Meeting
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* <div className="right">
            <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Single;
