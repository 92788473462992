// import React from "react";
// import { JitsiMeeting } from "@jitsi/react-sdk";

// const Room = () => {
//   return (
//     <div>
//       room
//       <JitsiMeeting
//         domain="YOUR_DOMAIN"
//         roomName="PleaseUseAGoodRoomName"
//         configOverwrite={{
//           startWithAudioMuted: true,
//           disableModeratorIndicator: true,
//           startScreenSharing: true,
//           enableEmailInStats: false,
//         }}
//         interfaceConfigOverwrite={{
//           DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
//         }}
//         userInfo={{
//           displayName: "YOUR_USERNAME",
//         }}
//         onApiReady={(externalApi) => {
//           // here you can attach custom event listeners to the Jitsi Meet External API
//           // you can also store it locally to execute commands
//         }}
//         getIFrameRef={(iframeRef) => {
//           iframeRef.style.height = "400px";
//         }}
//       />
//     </div>
//   );
// };

// export default Room;
// import React from "react";
// import { JitsiMeeting } from "@jitsi/react-sdk";

// const Room = () => {
//   return (
//     <div>
//       room
//       <JitsiMeeting
//         domain="YOUR_DOMAIN"
//         roomName="PleaseUseAGoodRoomName"
//         configOverwrite={{
//           startWithAudioMuted: true,
//           disableModeratorIndicator: true,
//           startScreenSharing: true,
//           enableEmailInStats: false,
//         }}
//         interfaceConfigOverwrite={{
//           DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
//         }}
//         userInfo={{
//           displayName: "YOUR_USERNAME",
//         }}
//         onApiReady={(externalApi) => {
//           // here you can attach custom event listeners to the Jitsi Meet External API
//           // you can also store it locally to execute commands
//         }}
//         getIFrameRef={(iframeRef) => {
//           iframeRef.style.height = "400px";
//         }}
//       />
//     </div>
//   );
// };

// export default Room;
import React from "react";
// import { Jitsi } from 'react-jitsi';

const Room = () => {
  return (
    <div>
      {/* <Jitsi
        roomName="YourRoomName"
        domain="meet.jit.si"
        configOverwrite={{
          startWithAudioMuted: true,
          disableModeratorIndicator: true,
          startScreenSharing: true,
          enableEmailInStats: false,
        }}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        }}
        userInfo={{
          displayName: "YOUR_USERNAME",
        }}
        onAPILoad={(jitsiApi) => {
          // Custom logic when the Jitsi Meet API is ready
        }}
        loadingComponent={<p>Loading ...</p>}
      /> */}
      {/* <div> */}
        <iframe
        title="Jitsi Meet"
        src="https://meet.jit.si/myVeryuniqueRoomforTry"
        allow="camera; microphone; fullscreen"
        style={{ height: "100vh", width: "100%" }}
      ></iframe>
      {/* </div> */}
    </div>
  );
};

export default Room;
