import React from "react";
import useFetch from "../../../hooks/useFetch";
import Subchapter from "../subchapter/Subchapter";
import "./chapterdetail.css";
const Chapterdetail = (props) => {
  const id = props.id;
  const detailId = props.detailId;
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/chapters/${props.detailId}`);
  // console.log("subdatas");
  const allDatadetail = data.details;
  // console.log("subdata", data);
  // console.log("sua", data.chapterName);
  // console.log("Detail", data.details);
  //   data.forEach( result => {
  //     console.log(result._id);
  //     console.log(result.chapterName);
  //     // console.log(result.createdBy);
  // })
  // const { data1, data2, data3 } = data
  // console.log("ine", data1);
  //   console.log("subdetails", data.details[0]);
  // console.log("sa", props.id);
  // console.log("detailId", props.detailId);
  return (
    <div className="wrapper">
      <div className="chapterName">Chapter 1: {data.chapterName}</div>
      <div className="detailChapter">
      {allDatadetail &&
        allDatadetail.map((oneData) => (
        <Subchapter key={oneData} ied={oneData} /> //detailed ID add
        ))}
      </div>
    </div>
  );
};
{/* <Chapterdetail key={oneData} id={id} detailId={oneData} /> */}

export default Chapterdetail;
