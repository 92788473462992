import React from "react";
import Logo1 from "./Logo.jpeg";
import "./navtop.css";
const Navtop = () => {
  return (
    <div className="navtop">
      <div className="logoSection">
        <a href="/">
          <img className="toplogo" src={Logo1} alt="logo" />
        </a>
        <div className="logoText">
          <h2>STUDENTRAYS</h2>
          <span>
          Rays is an innovative educational platform
          </span>
        </div>
      </div>
      <div>
        <a className="aNavtop" href="/session">
          BOOK FREE ASSESSMENT
        </a>
        <span>|</span>
        <a className="aNavtop" href="/complain">
          Raise A Complaint
        </a>
      </div>
    </div>
  );
};

export default Navtop;
