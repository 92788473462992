
// Grade 6 Science

export const gr6sci = {
  data: "CBSE Content for Class 6 Science",
  list:[
    {abelableContent: "Chapter 1 : Food Where Does It Come From"},
    {abelableContent: "Chapter 2 : Components of Food"},
    {abelableContent: "Chapter 3 : Fibre to Fabric"},
    {abelableContent: "Chapter 4 : Sorting Materials Into Groups"},
    {abelableContent: "Chapter 5 : Separation of Substances"},
    {abelableContent: "Chapter 6 : Changes Around Us"},
    {abelableContent: "Chapter 7 : Getting to Know Plants"},
    {abelableContent: "Chapter 8 : Body Movements"},
    {abelableContent: "Chapter 9 : The Living Organisms and Their Surroundings"},
    {abelableContent: "Chapter 10 : Motion and Measurement of Distances"},
    {abelableContent: "Chapter 11 : Light Shadows and Reflection"},
    {abelableContent: "Chapter 12 : Electricity and Circuits"},
    {abelableContent: "Chapter 13 : Fun with Magnets"},
    {abelableContent: "Chapter 14 : Water"},
    {abelableContent: "Chapter 15 : Air Around Us"},
    {abelableContent: "Chapter 16 : Garbage In Garbage Out"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// Grade 6 Maths
export const gr6mat = {
  data: "CBSE Content for Class 6 Maths",
  list:[
    {abelableContent: "Chapter : 1 Knowing Our Numbers"},
    {abelableContent: "Chapter : 2 Whole Numbers"},
    {abelableContent: "Chapter : 3 Playing With Numbers"},
    {abelableContent: "Chapter : 4 Basic Geometrical Ideas"},
    {abelableContent: "Chapter : 5 Understanding Elementary Shape"},
    {abelableContent: "Chapter : 6 Integers"},
    {abelableContent: "Chapter : 7 Fractions"},
    {abelableContent: "Chapter : 8 Decimals"},
    {abelableContent: "Chapter : 9 Data Handling"},
    {abelableContent: "Chapter : 10 Mensuration"},
    {abelableContent: "Chapter : 10 Mensuration"},
    {abelableContent: "Chapter : 11 Algebra"},
    {abelableContent: "Chapter : 12 Ratio and Proportion"},
    {abelableContent: "Chapter : 13 Symmetry"},
    {abelableContent: "Chapter : 14 Practical Geometry"},
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};

// Grade 7 Science
export const gr7sci = {
  data: "CBSE Content for Class 7 Science",
  list:[
    {abelableContent: "Chapter 1 Nutrition in Plants"},
    {abelableContent: "Chapter 2 Nutrition in Animals"},
    {abelableContent: "Chapter 3 Fibre to Fabric"},
    {abelableContent: "Chapter 4 Heat"},
    {abelableContent: "Chapter 5 Acids, Bases and Salts"},
    {abelableContent: "Chapter 6 Physical and Chemical Changes"},
    {abelableContent: "Chapter 7 Weather, Climate and Adaptations of Animals to Climate"},
    {abelableContent: "Chapter 8 Winds, Storms and Cyclones"},
    {abelableContent: "Chapter 9 Soil"},
    {abelableContent: "Chapter 10 Respiration in Organisms"},
    {abelableContent: "Chapter 11 Transportation in Animals and Plants"},
    {abelableContent: "Chapter 12 Reproduction in Plants"},
    {abelableContent: "Chapter 13 Motion and Time"},
    {abelableContent: "Chapter 14 Electric Current and Its Effects"},
    {abelableContent: "Chapter 15 Light"},
    {abelableContent: "Chapter 16 Water: A Precious Resource"},
    {abelableContent: "Chapter 17 Forests: Our Lifeline"},
    {abelableContent: "Chapter 18 Wastewater Story"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// Grade 7 Maths
export const gr7mat = {
  data: "CBSE Content for Class 7 Maths",
  list:[
    {abelableContent: "Chapter 1 Integers"},
    {abelableContent: "Chapter 2 Fractions and Decimals"},
    {abelableContent: "Chapter 3 Data Handling"},
    {abelableContent: "Chapter 4 Simple Equations"},
    {abelableContent: "Chapter 5 Lines and Angles"},
    {abelableContent: "Chapter 6 The Triangles and its Properties"},
    {abelableContent: "Chapter 7 Congruence of Triangles"},
    {abelableContent: "Chapter 8 Comparing Quantities"},
    {abelableContent: "Chapter 9 Rational Numbers"},
    {abelableContent: "Chapter 10 Practical Geometry"},
    {abelableContent: "Chapter 11 Perimeter and Area"},
    {abelableContent: "Chapter 12 Algebraic Expressions"},
    {abelableContent: "Chapter 13 Exponents and Powers"},
    {abelableContent: "Chapter 14 Symmetry"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// Grade 8 Science

export const gr8sci = {
  data: "CBSE Content for Class 8 Science",
  list:[
    {abelableContent: "Chapter 1 Crop Production and Management"},
    {abelableContent: "Chapter 2 Microorganisms: Friend and Foe"},
    {abelableContent: "Chapter 3 Synthetic Fibres and Plastics"},
    {abelableContent: "Chapter 4 Materials: Metals and Non-Metals"},
    {abelableContent: "Chapter 5 Coal and Petroleum"},
    {abelableContent: "Chapter 6 Combustion and Flame"},
    {abelableContent: "Chapter 7 Conservation of Plants and Animals"},
    {abelableContent: "Chapter 8 Cell Structure and Functions"},
    {abelableContent: "Chapter 9 Reproduction in Animals"},
    {abelableContent: "Chapter 10 Reaching the Age of Adolescence"},
    {abelableContent: "Chapter 11 Force and Pressure"},
    {abelableContent: "Chapter 12 Friction"},
    {abelableContent: "Chapter 13 Sound"},
    {abelableContent: "Chapter 14 Chemical Effects of Electric Current"},
    {abelableContent: "Chapter 15 Some Natural Phenomena"},
    {abelableContent: "Chapter 16 Light"},
    {abelableContent: "Chapter 17 Stars and the Solar System"},
    {abelableContent: "Chapter 18 Pollution of Air and Water"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// Grade 8  Maths
	
export const gr8mat = {
  data: "CBSE Content for Class 8 Maths",
  list:[
    {abelableContent: "Chapter 1 Rational Numbers"},
    {abelableContent: "Chapter 2 Linear Equations in One Variable"},
    {abelableContent: "Chapter 3 Understanding Quadrilaterals"},
    {abelableContent: "Chapter 4 Practical Geometry"},
    {abelableContent: "Chapter 5 Data Handling"},
    {abelableContent: "Chapter 6 Squares and Square Roots"},
    {abelableContent: "Chapter 7 Cubes and Cube Roots"},
    {abelableContent: "Chapter 8 Comparing Quantities"},
    {abelableContent: "Chapter 9 Algebraic Expressions and Identities"},
    {abelableContent: "Chapter 10 Visualising Solid Shapes"},
    {abelableContent: "Chapter 11 Mensuration"},
    {abelableContent: "Chapter 12 Exponents and Powers"},
    {abelableContent: "Chapter 13 Direct and Indirect proportions"},
    {abelableContent: "Chapter 14 Factorisation"},
    {abelableContent: "Chapter 15 Introduction to Graphs"},
    {abelableContent: "Chapter 16 Playing with Numbers"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};


// Grade 9 Science
export const gr9sci = {
  data: "CBSE Content for Class 9 Science",
  list:[
    {abelableContent: "Chapter 1 Matter in Our Surroundings"},
    {abelableContent: "Chapter 2 Is Matter Around Us Pure"},
    {abelableContent: "Chapter 3 Atoms and Molecules"},
    {abelableContent: "Chapter 4 Structure of the Atom"},
    {abelableContent: "Chapter 5 The Fundamental Unit of Life"},
    {abelableContent: "Chapter 6 Tissues"},
    {abelableContent: "Chapter 7 Diversity in Living Organisms"},
    {abelableContent: "Chapter 8 Motion"},
    {abelableContent: "Chapter 9 Force and Laws of Motion"},
    {abelableContent: "Chapter 10 Gravitation"},
    {abelableContent: "Chapter 11 Work, Power And Energy"},
    {abelableContent: "Chapter 12 Sound"},
    {abelableContent: "Chapter 13 Why Do we Fall Ill"},
    {abelableContent: "Chapter 14 Natural Resources"},
    {abelableContent: "Chapter 15 Improvement in Food Resources"},
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
	

// Grade 9 Maths
export const gr9mat = {
  data: "CBSE Content for Class 9 Maths",
  list:[
    {abelableContent: "Chapter 1 Number systems"},
    {abelableContent: "Chapter 2 Polynomials"},
    {abelableContent: "Chapter 3 Coordinate Geometry"},
    {abelableContent: "Chapter 4 Linear Equations in Two Variables"},
    {abelableContent: "Chapter 5 Introduction to Euclid Geometry"},
    {abelableContent: "Chapter 6 Lines and Angles"},
    {abelableContent: "Chapter 7 Triangles"},
    {abelableContent: "Chapter 8 Quadrilaterals"},
    {abelableContent: "Chapter 9 Areas of Parallelograms and Triangles"},
    {abelableContent: "Chapter 10 Circles"},
    {abelableContent: "Chapter 11 Constructions"},
    {abelableContent: "Chapter 12 Heron’s Formula"},
    {abelableContent: "Chapter 13 Surface Areas and Volumes"},
    {abelableContent: "Chapter 14 Statistics"},
    {abelableContent: "Chapter 15 Probability"},
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};


// Grade 10 Science
export const gr10sci = {
  data: "CBSE Content for Class 10 Science",
  list:[
    {abelableContent: "Chapter 1 : Chemical Reactions and Equations"},
    {abelableContent: "Chapter 2 : Acids, Bases, and Salts"},
    {abelableContent: "Chapter 3 : Metals and Non-metals"},
    {abelableContent: "Chapter 4 : Carbon and its Compounds"},
    {abelableContent: "Chapter 5 : Life Processes"},
    {abelableContent: "Chapter 6 : Control and Coordination"},
    {abelableContent: "Chapter 7 : How do Organisms Reproduce?"},
    {abelableContent: "Chapter 8 : Heredity"},
    {abelableContent: "Chapter 9 : Light – Reflection and Refraction"},
    {abelableContent: "Chapter 10 : The Human Eye and the Colourful World"},
    {abelableContent: "Chapter 11 : Electricity"},
    {abelableContent: "Chapter 12 : Magnetic Effects of Electric Current"},
    {abelableContent: "Chapter 13 : Our Environment"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};

// Grade 10 Maths
export const gr10mat = {
  data: "CBSE Content for Class 10 Maths",
  list:[
    {abelableContent: "Chapter 1 Real Numbers"},
    {abelableContent: "Chapter 2 Polynomials"},
    {abelableContent: "Chapter 3 Pair of Linear Equations in Two Variables"},
    {abelableContent: "Chapter 4 Quadratic Equations"},
    {abelableContent: "Chapter 5 Arithmetic Progressions"},
    {abelableContent: "Chapter 6 Triangles"},
    {abelableContent: "Chapter 7 Coordinate Geometry"},
    {abelableContent: "Chapter 8 Introduction to Trigonometry"},
    {abelableContent: "Chapter 9 Some Applications of Trigonometry"},
    {abelableContent: "Chapter 10 Circles"},
    {abelableContent: "Chapter 11 Areas Related to Circles"},
    {abelableContent: "Chapter 12 Surface Areas and Volumes"},
    {abelableContent: "Chapter 13 Statistics"},
    {abelableContent: "Chapter 14 Probability"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};








// ICSE
// ICSE Grade 6 Science
export const icse6sci = {
  data: "ICSE Content for Class 6 Science",
  list:[
    {abelableContent: "Physics: Introduction to light, sound, and electricity"},
    {abelableContent: "Chemistry: Basics of matter, elements, and compounds"},
    {abelableContent: "Biology: Study of plants, animals, and the human body"},
    {abelableContent: "Environmental Science: Awareness of environmental issues and conservation"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 6 Maths
export const icse6mat = {
  data: "ICSE Content for Class 6 Maths",
  list:[
    {abelableContent: "Number System: Integers, fractions, decimals, and powers and roots"},
    {abelableContent: "Algebra: Basic algebraic expressions and equations"},
    {abelableContent: "Geometry: Basic geometry concepts"},
    {abelableContent: "Mensuration: Introduction to concepts like area and perimeter"},
    {abelableContent: "Data Handling: Introduction to data interpretation and representation"},
    {abelableContent: "Simple Equations"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};

// ICSE Grade 7 Science
export const icse7sci = {
  data: "ICSE Content for Class 7 Science",
  list:[
    {abelableContent: "Physics: Concepts related to motion, force, heat, and light"},
    {abelableContent: "Chemistry: Basics of elements, compounds, and chemical reactions"},
    {abelableContent: "Biology: Study of plants, animals, and human body systems"},
    {abelableContent: "Environmental Science: Awareness of environmental issues and conservation"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 7 Maths
export const icse7mat = {
  data: "ICSE Content for Class 7 Maths",
  list:[
    {abelableContent: "Number System: Fractions, decimals, percentages, and rational numbers"},
    {abelableContent: "Algebra: Algebraic expressions and equations"},
    {abelableContent: "Geometry: Plane and solid geometry"},
    {abelableContent: "Mensuration: Area, volume, and surface area calculations"},
    {abelableContent: "Data Handling: Collection, organization, and interpretation of data"},
    {abelableContent: "Simple Equations and Ratio and Proportion"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};

// ICSE Grade 8 Science
export const icse8sci = {
  data: "ICSE Content for Class 8 Science",
  list:[
    {abelableContent: "Physics: Concepts related to motion, force, light, sound, and electricity"},
    {abelableContent: "Chemistry: Elements, compounds, chemical reactions, and the periodic table"},
    {abelableContent: "Biology: Study of human body systems, health, and diseases"},
    {abelableContent: "Environmental Science: Awareness of environmental issues, conservation, and ecosystem"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 8 Maths
export const icse8mat = {
  data: "ICSE Content for Class 8 Maths",
  list:[
    {abelableContent: "Number System: Rational numbers, integers, and number line"},
    {abelableContent: "Algebra: Linear equations, exponents, and polynomials"},
    {abelableContent: "Geometry: Plane and solid geometry, congruence, and similarity"},
    {abelableContent: "Mensuration: Area, volume, and surface area calculations"},
    {abelableContent: "Data Handling: Statistics and probability"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 9 Science
export const icse9sci = {
  data: "ICSE Content for Class 9 Science",
  list:[
    {abelableContent: "Physics: Advanced topics in mechanics, energy, and electricity"},
    {abelableContent: "Chemistry: Chemical reactions, the periodic table, and chemical bonding"},
    {abelableContent: "Biology: Study of cells, tissues, and the human circulatory system"},
    {abelableContent: "Environmental Science: Deeper exploration of environmental issues and conservation"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 9 Maths
export const icse9mat = {
  data: "ICSE Content for Class 9 Maths",
  list:[
    {abelableContent: "Number System: Real numbers, rational and irrational numbers, and laws of exponents"},
    {abelableContent: "Algebra: Polynomials, linear equations in two variables, and quadratic equations"},
    {abelableContent: "Geometry: Properties of triangles, quadrilaterals, and circles"},
    {abelableContent: "Mensuration: Areas and volumes"},
    {abelableContent: "Coordinate Geometry"},
    {abelableContent: "Probability and Statistics"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 10 Science
export const icse10sci = {
  data: "ICSE Content for Class 10 Science",
  list:[
    {abelableContent: "Physics: Topics include light, electricity, magnetism, and modern physics"},
    {abelableContent: "Chemistry: Chemical reactions, periodic table, chemical bonding, and organic chemistry"},
    {abelableContent: "Biology: Study of cells, tissues, and the human circulatory system, along with environmental topics"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 10 Maths
export const icse10mat = {
  data: "ICSE Content for Class 10 Maths",
  list:[
    {abelableContent: "Real Numbers, Rational Numbers, and Laws of Exponents"},
    {abelableContent: "Quadratic Equations and Arithmetic Progressions"},
    {abelableContent: "Coordinate Geometry"},
    {abelableContent: "Geometry: Properties of triangles, quadrilaterals, and circles"},
    {abelableContent: "Trigonometry"},
    {abelableContent: "Mensuration: Areas and volumes"},
    {abelableContent: "Probability and Statistics"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
