import React from "react";
import useFetch from "../../../hooks/useFetch";
import MiniTopics from "./MiniTopics";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const Details = (props) => {
  const { data: details } = useFetch(`https://api.studentrays.com/api/details/${props.id}`);
  console.log("details", details);
  const { miniTopics } = details;
  const score = props.scoredata;
  console.log("score", score);
  return (
    <div className="subchapter">
      <div>
        <span className="subchapterTopic bold"> Sub Chapter: </span>
        <span className="subchaptername">{details.SubTopic}</span>
      </div>
      <div style={{ display: "flex", "flex-direction": "column" }}>
        {miniTopics &&
          miniTopics.map((id, index) => (
            <MiniTopics
              key={index}
              id={id}
              compdata={props.compdata}
              scoredata={props.scoredata}
            />
          ))}
        <div>
          {score &&
            score.map((data, index) =>
              details._id === data.detailId ? (
                <div>
                  <span className="subchapterTopic bold"> Score: </span>
                  <span className="subchaptername">{data.score}</span>
                  <CheckCircleIcon className="green" />
                </div>
              ) : (
                ""
              )
            )}
          {/* <CheckCircleIcon className="green" /> */}
          {/* <span className="subchapterTopic bold"> Score: </span>
          <span className="subchaptername">{details.SubTopic}</span> */}
        </div>
      </div>
    </div>
  );
};

export default Details;
