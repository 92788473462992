import React, { useState, useRef } from "react";
import YouTube from "react-youtube";
import "./videoPlayers.css";
import video from "./video.mp4";
const VideoPages = () => {
  const videoId = "pwcB3BkFWJI"; // Replace with your actual YouTube video ID
  const [player, setPlayer] = useState(null);

  const opts = {
    height: "315",
    width: "393",
    playerVars: {
      autoplay: 0, // Auto play when the page loads
    },
  };

  const onReady = (event) => {
    setPlayer(event.target);
  };

  const playVideoFromBeginning = () => {
    if (player) {
      player.seekTo(0);
      player.playVideo();
    }
  };

  return (
    <div>
      {/* <h1>Watch the Video</h1> */}
      <div onClick={playVideoFromBeginning}>
        {/* <YouTube videoId={videoId} opts={opts} onReady={onReady} /> style={{ width:'50rem' }} className="videoPlayers" */}
        <video style={{ width: "20rem" }} controls>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default VideoPages;
