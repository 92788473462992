// import React, { useEffect, useState } from "react";
// import DashNavbar from "./navbar/DashNavbar";
// import { Link } from "react-router-dom";
// import { userColumns } from "./datatable/datatablesource";
// import Datatable from "./datatable/Datatable";
// import Sidebar from "./sidebar/Sidebar";
// import "./teacherdashboard.css";
// import { AuthContext } from "./../../context/AuthContext";
// import { useContext } from "react";
// import useFetch from "./../../hooks/useFetch";
// import moment from "moment";
// const Teacherdashboard = () => {
//   const { user } = useContext(AuthContext);

//   // const { data, loading, error } = useFetch(`https://api.studentrays.com/api/events/teacher/${user._id}`);
//   // console.log("data", data);
//   // const studentObjects = data.filter((item) => item.isTeacher);
//   const { data: calenderData } = useFetch(
//     `https://api.studentrays.com/api/calender/${user._id}`
//   );
//   console.log("calenderData", calenderData);
//   const [nextEvent, setNextEvent] = useState(null);

//   useEffect(() => {
//     if (calenderData && calenderData.length > 0) {
//       console.log("calenderData:", calenderData);
  
//       const now = moment();
//       console.log("Current date and time:", now.format('YYYY-MM-DD HH:mm:ss'));
  
//       const upcomingEvents = calenderData
//         .map((event) => {
//           const eventDate = moment(event.Date);
//           const [startHour, startMinute] = event.startTime.split(":").map(Number);
//           const [endHour, endMinute] = event.endTime.split(":").map(Number);
  
//           const start = eventDate.clone().set({ hour: startHour, minute: startMinute });
//           const end = eventDate.clone().set({ hour: endHour, minute: endMinute });
  
//           return { ...event, start, end };
//         })
//         .filter((event) => event.end.isAfter(now))
//         .sort((a, b) => a.start - b.start);
  
//       console.log("Upcoming events:", upcomingEvents);
  
//       if (upcomingEvents.length > 0) {
//         setNextEvent(upcomingEvents[0]);
//         console.log("Next event:", upcomingEvents[0]);
//       } else {
//         console.log("No upcoming events found");
//       }
  
//       // Log all events for debugging
//       calenderData.forEach((event, index) => {
//         const start = moment(event.Date).set({
//           hour: event.startTime.split(":")[0],
//           minute: event.startTime.split(":")[1]
//         });
//         const end = moment(event.Date).set({
//           hour: event.endTime.split(":")[0],
//           minute: event.endTime.split(":")[1]
//         });
//         console.log(
//           `Event ${index}: Date: ${event.Date}, ` +
//           `Start: ${start.format('HH:mm')}, End: ${end.format('HH:mm')}, ` +
//           `Is upcoming: ${end.isAfter(now)}`
//         );
//       });
//     }
//   }, [calenderData]);
  
//   if (!nextEvent) {
//     console.log("No upcoming events 75");
//     return <p>No upcoming events</p>;
    
//   }
//   // useEffect(() => {
//   //   if (calenderData && calenderData.length > 0) {
//   //     console.log("calenderData 25", calenderData);

//   //     const now = moment(); // Adjust the current time as needed
//   //     // const now = moment().add(3, "hours"); // Adjust the current time as needed
//   //     console.log("Adjusted current time:", now.format());

//   //     const upcomingEvents = calenderData
//   //       .map((event, index) => {
//   //         const eventDate = moment(event.Date);
//   //         const [startHour, startMinute] = event.startTime
//   //           .split(":")
//   //           .map(Number);
//   //         const [endHour, endMinute] = event.endTime.split(":").map(Number);

//   //         const start = eventDate
//   //           .clone()
//   //           .set({ hour: startHour, minute: startMinute });
//   //         const end = eventDate
//   //           .clone()
//   //           .set({ hour: endHour, minute: endMinute });
//   //         console.log(
//   //           `Event ${index} end time:`,
//   //           end.format(),
//   //           "Is before now:",
//   //           now.isBefore(end)
//   //         );
//   //         return { ...event, start, end };
//   //       })
//   //       // .filter((event) => now.isBefore(event.end));
//   //       .filter((event) => 
//   //         event.start.isAfter(now) || 
//   //         (now.isBetween(event.start, event.end)) ||
//   //         (event.start.isSame(now, 'day') && event.end.isAfter(now))
//   //       )
//   //     upcomingEvents.sort((a, b) => a.start - b.start);
//   //     console.log("upcomingEvents 49", upcomingEvents);

//   //     if (upcomingEvents.length > 0) {
//   //       setNextEvent(upcomingEvents[0]);
//   //       console.log("Next event:", upcomingEvents[0]);
//   //     }
//   //   }
//   // }, [calenderData]);

//   // if (!nextEvent) {
//   //   return <p>No event</p>;
//   //   // console.log("No event");
//   // }

//   const now = moment(); // Ensure the current time is correctly adjusted here too
//   // const now = moment().add(3, "hours"); // Ensure the current time is correctly adjusted here too
//   const isOngoing = now.isBetween(nextEvent.start, nextEvent.end);
//   const isUpcoming = nextEvent.start.diff(now, "minutes") <= 15;
//   // Extract unique batches
//   const uniqueBatches = Array.from(
//     new Set(calenderData.map((item) => item.batch))
//   );

//   return (
//     <>
//       <div className="fixed">
//         <DashNavbar />
//       </div>
//       <div className="teacherflex">
//         <Sidebar />
//         <div className="flexdata">
//           <Link to={`/teacherdashboard/teacherCalender/${user._id}`}>
//             <button className="btn btn-primary">Calender</button>
//           </Link>
         
//           <div>
//             {isOngoing || isUpcoming ? (
//               nextEvent.batch === "councellor" ? (
//                 <>
//                   <p>Meeting with {nextEvent.batch}</p>
//                   <button
//                     onClick={() => window.open(nextEvent.joinUrl, "_blank")}
//                   >
//                     Join meeting with Councellor
//                   </button>
//                 </>
//               ) : (
//                 <>
//                   <p>Meeting for batch {nextEvent.batch}</p>
//                   <button
//                     className="btn btn-primary"
//                     onClick={() => window.open(nextEvent.teacherUrl, "_blank")}
//                   >
//                     Join
//                   </button>
//                 </>
//               )
//             ) : (
//               <p>Next event starts at {nextEvent.start.format("HH:mm")}</p>
//             )}
//             <div>
//               {uniqueBatches.map((batch) => {
//                 // Find the last occurrence of the event for the current batch
//                 const lastEvent = calenderData
//                   .slice()
//                   .reverse()
//                   .find((calendarEvent) => calendarEvent.batch === batch);

//                 return (
//                   <div key={batch}>
//                     {batch !== "councellor" ? (
//                       <>
//                         <p>{batch}</p>
//                         {lastEvent && (
//                           <div>
//                             <Datatable
//                               key={lastEvent._id} 
//                               columns={userColumns}
//                               teacherAssigned={lastEvent.teacherid} // Access lastEvent's teacherid
//                               batch={lastEvent.batch} // Access lastEvent's batch
//                             />
//                           </div>
//                         )}
//                       </>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 );
//               })}

//             </div>
//           </div>
        
//         </div>
//       </div>
//     </>
//   );
// };

// export default Teacherdashboard;
import React, { useEffect, useState } from "react";
import DashNavbar from "./navbar/DashNavbar";
import { Link } from "react-router-dom";
import { userColumns } from "./datatable/datatablesource";
import Datatable from "./datatable/Datatable";
import Sidebar from "./sidebar/Sidebar";
import "./teacherdashboard.css";
import { AuthContext } from "./../../context/AuthContext";
import { useContext } from "react";
import useFetch from "./../../hooks/useFetch";
import moment from "moment";

const Teacherdashboard = () => {
  const { user } = useContext(AuthContext);
  const { data: calenderData } = useFetch(
    `https://api.studentrays.com/api/calender/${user._id}`
  );
  
  const [nextEvent, setNextEvent] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    if (calenderData && calenderData.length > 0) {
      const now = moment();

      const upcoming = calenderData
        .map((event) => {
          const eventDate = moment(event.Date);
          const [startHour, startMinute] = event.startTime.split(":").map(Number);
          const [endHour, endMinute] = event.endTime.split(":").map(Number);

          const start = eventDate.clone().set({ hour: startHour, minute: startMinute });
          const end = eventDate.clone().set({ hour: endHour, minute: endMinute });

          return { ...event, start, end };
        })
        .filter((event) => event.end.isAfter(now)) // Only future events
        .sort((a, b) => a.start - b.start);

      setUpcomingEvents(upcoming);

      if (upcoming.length > 0) {
        setNextEvent(upcoming[0]); // Set the next event
      }
    }
  }, [calenderData]);

  if (!calenderData || calenderData.length === 0) {
    return <p>Loading or no events available</p>;
  }

  const now = moment();
  const isOngoing = nextEvent && now.isBetween(nextEvent.start, nextEvent.end);
  const isUpcoming = nextEvent && nextEvent.start.diff(now, "minutes") <= 15;

  // Extract unique batches
  const uniqueBatches = Array.from(new Set(calenderData.map((item) => item.batch)));

  return (
    <>
      <div className="fixed">
        <DashNavbar />
      </div>
      <div className="teacherflex">
        <Sidebar />
        <div className="flexdata">
          <Link to={`/teacherdashboard/teacherCalender/${user._id}`}>
            <button className="btn btn-primary">Calender</button>
          </Link>
         
          <div>
            {nextEvent && (isOngoing || isUpcoming) ? (
              nextEvent.batch === "councellor" ? (
                <>
                  <p>Meeting with {nextEvent.batch}</p>
                  <button onClick={() => window.open(nextEvent.joinUrl, "_blank")}>
                    Join meeting with Councellor
                  </button>
                </>
              ) : (
                <>
                  <p>Meeting for batch {nextEvent.batch}</p>
                  <button className="btn btn-primary" onClick={() => window.open(nextEvent.teacherUrl, "_blank")}>
                    Join
                  </button>
                </>
              )
            ) : nextEvent ? (
              <p>Next event starts at {nextEvent.start.format("HH:mm")}</p>
            ) : (
              <p>No upcoming events</p>
            )}

            <div>
              {uniqueBatches.map((batch) => {
                const lastEvent = calenderData
                  .slice()
                  .reverse()
                  .find((calendarEvent) => calendarEvent.batch === batch);

                return (
                  <div key={batch}>
                    {batch !== "councellor" && lastEvent && (
                      <>
                        <p>{batch}</p>
                        <div>
                          <Datatable
                            key={lastEvent._id}
                            columns={userColumns}
                            teacherAssigned={lastEvent.teacherid}
                            batch={lastEvent.batch}
                          />
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teacherdashboard;
