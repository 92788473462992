import React from "react";
import "./fixedsidebar.css";
import GoogleMaps from "./GoogleMaps.png";
import LinkedIn from "./LinkedIn.png";
import WhatsApp from "./WhatsApp.png";
import Call from "./Call.png";
const Fixedsidebar = () => {
  return (
    <div className="fixedsidebarout">
      <div className="fixedsidebar">
        {/* <a
          href="https://web.whatsapp.com/send?phone=8188854509"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="WhatsappImg" style={{"width":"25px"}} src={Call} alt="Call" />
        </a> */}
        <a
          href="https://web.whatsapp.com/send?phone=8056287844"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="WhatsappImg" src={WhatsApp} alt="WhatsApp" />
        </a>
        {/* <a
          href="https://web.whatsapp.com/send?phone=8188854509"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="WhatsappImg" src={GoogleMaps} alt="GoogleMaps" />
        </a>
        <a
          href="https://web.whatsapp.com/send?phone=8188854509"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="WhatsappImg" src={LinkedIn} alt="LinkedIn" />
        </a> */}
      </div>
    </div>
  );
};

export default Fixedsidebar;
