import React from "react";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import { Link, useLocation } from "react-router-dom";
import { freesessionColumns } from "../datatable/datatablesource";
import Datatable from "../datatable/Datatable";
import useFetch from "../../../hooks/useFetch";
import "./onedata.scss"
const CounsellorOneData = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const paths = location.pathname.split("/")[3];
  console.log("path",path);
  console.log("paths",paths);
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/${path}/find/${paths}`);
  console.log("Datatable Data", data);
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">Information</h1>
            <div className="item">
              <div className="details">
                <h1 className="itemTitle">{data.name}</h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{data.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Mobile:</span>
                  <span className="itemValue">{data.mobile}</span>
                </div>
                {path && path === "freesessions"?
                <div className="detailItem">
                  <span className="itemKey">State:</span>
                  <span className="itemValue">{data.state}</span>
                </div>:
                <div className="detailItem">
                  <span className="itemKey">Complaintext:</span>
                  <span className="itemValue">{data.complaintext}</span>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounsellorOneData;
