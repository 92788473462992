// import React, { useState, useEffect } from "react";
// import useFetch from "../../../hooks/useFetch";
// import { useLocation, useNavigate } from "react-router-dom";
// import { AuthContext } from "../../../context/AuthContext";
// import { useContext } from "react";
// import "./quest.css";

// const Check = () => {
//   const { user } = useContext(AuthContext);
//   //   console.log("user", user);
//   const location = useLocation();
//   const id = location.pathname.split("/")[3];
//   //   console.log("queId", id);
//   let { data, loading, error } = useFetch(`/details/${id}`);
//   //   console.log("quedata", data);
//   let { data: users } = useFetch(`/users/${user._id}`);
//   console.log("usersdata", users);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   //   const allQuestions = data.questions;
//   const allQuestions = data && data.questions ? data.questions : [];
//   const questions = data && data.questions ? data.questions : [];
//   //   console.log("allQuestions", allQuestions);
//   //   const lengthOfQuestions = allQuestions.length;
//   console.log("lengthOfQuestions", allQuestions.length);
//   const lengthOfQuestions = Math.max(allQuestions.length - 1, 0);
//   const [answers, setAnswers] = useState(Array(lengthOfQuestions).fill(""));
//   //   const [isButtonDisabled, setButtonDisabled] = useState(true);
//   // const [questions, setQuestions] = useState(data.questions);
//   //   const [questions, setQuestions] = useState(
//   //     data && data.questions ? data.questions : []
//   //   )
//   console.log("questions", questions);
//   //   console.log("allQuestions", allQuestions);
//   const [totalScore, setTotalScore] = useState(0);
//   const [submitted, setSubmitted] = useState(false);
//   //   const [matchingComptopics, setMatchingComptopics] = useState([]);
//   const [apiUrl] = useState(`/scores/${users._id}`);
//   const currentQuestion = questions[currentQuestionIndex];
//   console.log("user score", users.scores);
//   //   const comp = user.comptopics;

//   const handleNextClick = () => {
//     const currentQuestion = questions[currentQuestionIndex];

//     if (answers[currentQuestionIndex] !== "") {
//       if (currentQuestionIndex < questions.length - 1) {
//         setCurrentQuestionIndex(currentQuestionIndex + 1);
//       } else {
//         calculateTotalScore();
//         setSubmitted(true);
//         sendData();
//       }
//     }
//   };

//   const handleOptionChange = (e) => {
//     const { value } = e.target;
//     answers[currentQuestionIndex] = value;
//     setAnswers([...answers]);
//     // console.log("answers",answers);
//     // console.log("currentQuestionIndex",currentQuestionIndex);
//     // setCheckButtonDisabled(false);
//     // setButtonDisabled(false);
//   };

//   const calculateTotalScore = () => {
//     let newTotalScore = 0;

//     for (let i = 0; i < answers.length; i++) {
//       const selectedOption = answers[i];
//       const question = questions[i];
//       if (selectedOption === question.correctAnswer) {
//         newTotalScore += 1;
//       }
//     }

//     setTotalScore(newTotalScore);
//   };
//   //   console.log("answers[currentQuestionIndex]", answers[currentQuestionIndex]);
//   const isButtonDisabled = answers[currentQuestionIndex] === "";

//   const sendData = () => {
//     const dataToSend = {
//       detailId: "props.currentId",
//       score: totalScore, // Assuming totalScore is in scope
//     };
//     if (Array.isArray(subtopicIds) && subtopicIds.includes("props.currentId")) {
//       console.log(" is already present in minitopic.");
//     } else {
//       console.log(" is not present in minitopic.");
//       fetch(apiUrl, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(dataToSend),
//       })
//         .then((response) => {
//           if (!response.ok) {
//             throw new Error("Network response was not ok");
//           }
//           return response.json();
//         })
//         .then((data) => {
//           // Handle the response data if needed
//           console.log("Data sent successfully:", data);
//         })
//         .catch((error) => {
//           // Handle any errors that occurred during the fetch
//           console.error("Error sending data:", error);
//         });
//     }
//   };
//   return (
//     <div>
//       <div className="Qtop">
//         {currentQuestion && (
//           <div>
//             <p>{currentQuestion.question}</p>
//             <ul>
//               {currentQuestion.options.map((option, index) => (
//                 <li className="inpitli" key={index}>
//                   <label>
//                     <input
//                       className="inpitForm"
//                       type="radio"
//                       name="options"
//                       value={option}
//                       checked={answers[currentQuestionIndex] === option}
//                       onChange={handleOptionChange}
//                       required // Make input required
//                     />
//                     {option}
//                   </label>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         )}

//         {submitted ? (
//           <div>
//             <p className="submitResult">You have scored: {totalScore}</p>
//             <p className="submitResult">You can try again</p>

//             <button
//               className="nextButton"
//               onClick={() => window.location.reload()}
//             >
//               Try Again
//             </button>
//           </div>
//         ) : (
//           <button
//             className="nextButton"
//             onClick={handleNextClick}
//             disabled={isButtonDisabled}
//           >
//             {currentQuestionIndex === questions.length - 1 ? "Submit" : "Next"}
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Check;
import React, { useState, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import "./quest.css";

const Check = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  let { data, loading, error } = useFetch(`https://api.studentrays.com/api/details/${id}`);
  let { data: users } = useFetch(`https://api.studentrays.com/api/users/${user._id}`);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const allQuestions = data && data.questions ? data.questions : [];
  const questions = data && data.questions ? data.questions : [];
  const lengthOfQuestions = Math.max(allQuestions.length - 1, 0);
  const [answers, setAnswers] = useState(Array(lengthOfQuestions).fill(""));
  const [totalScore, setTotalScore] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to track button disabled state

  const currentQuestion = questions[currentQuestionIndex];
  const [matchingComptopics, setMatchingComptopics] = useState();
  const [apiUrl] = useState(`https://api.studentrays.com/api/scores/${user._id}`);
  const comp = users.scores;
  console.log("user m", comp);
  console.log("usern", users.username);
  // console.log("user name", user.username);
  useEffect(() => {
    if (data._id && comp) {
      const matching = comp.map((oneData) => {
        if(oneData.detailId === data._id){
          return setMatchingComptopics(oneData.detailId)
       }
        // return comp.filter((comptopic) => comptopic.id === oneData);
      });
      console.log("matching",matching);
      // const flattenedMatching = matching; // Flatten the array of arrays
      // const flattenedMatching = matching.flat(); // Flatten the array of arrays
      // setMatchingComptopics(flattenedMatching);
    }
  }, [data._id, comp]);
  // console.log("matching",matching);
  console.log("matchingComptopics", matchingComptopics);
  // const subtopicIds = matchingComptopics.map((item) => item.subtopicId);
  // console.log("subtopicIds",subtopicIds);





  // Function to handle option change
  const handleOptionChange = (e) => {
    const { value } = e.target;
    answers[currentQuestionIndex] = value;
    setAnswers([...answers]);
    // Check if any option is selected
    setIsButtonDisabled(false);
  };

  const handleNextClick = () => {
    const currentQuestion = questions[currentQuestionIndex];
    setIsButtonDisabled(true);
    if (answers[currentQuestionIndex] !== "") {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        const tot = calculateTotalScore();
        setSubmitted(true);
        sendData(tot);
        console.log("totalScore",totalScore);
      }
    }
  };

  const calculateTotalScore = () => {
    let newTotalScore = 0;

    for (let i = 0; i < answers.length; i++) {
      const selectedOption = answers[i];
      const question = questions[i];
      if (selectedOption === question.correctAnswer) {
        newTotalScore += 1;
      }
    }

    setTotalScore(newTotalScore);
    console.log("totalScore1",newTotalScore);
    return newTotalScore;
  };

  const sendData = (tot) => {
    console.log("tot",tot);
    const dataToSend = {
      detailId: data._id,
      score: tot,
    };
    if (matchingComptopics == data._id ) {
      console.log(" is already present in minitopic.");
    } else {
      console.log(" is not present in minitopic.");
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Handle the response data if needed
          console.log("Data sent successfully:", data);
        })
        .catch((error) => {
          // Handle any errors that occurred during the fetch
          console.error("Error sending data:", error);
        });
    }
  };

  return (
    <div>
      <div className="Qtop">
        {currentQuestion && (
          <div>
            <p>{currentQuestion.question}</p>
            <ul>
              {currentQuestion.options.map((option, index) => (
                <li className="inpitli" key={index}>
                  <label>
                    <input
                      className="inpitForm"
                      type="radio"
                      name="options"
                      value={option}
                      checked={answers[currentQuestionIndex] === option}
                      onChange={handleOptionChange}
                      required // Make input required
                    />
                    {option}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        )}

        {submitted ? (
          <div>
            <p className="submitResult">You have scored: {totalScore}</p>
            <p className="submitResult">You can try again</p>
            <button
              className="nextButton"
              onClick={() => window.location.reload()}
            >
              Try Again
            </button>
          </div>
        ) : (
          <button
            className="nextButton"
            onClick={handleNextClick}
            disabled={isButtonDisabled} // Disable the button if no option is selected
          >
            {currentQuestionIndex === questions.length - 1 ? "Submit" : "Next"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Check;
