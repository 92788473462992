import React from "react";
import Navb from "../../assets/components/nav/Navb";
import Navtop from "../../assets/components/navtop/Navtop";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Footer from "../../assets/components/footer/Footer";
import ScrollButton from "../../assets/components/scroll/Scroll";
import "./session.css";
import { useState } from "react";
const Session = () => {
  const [formSummit, setformSummit] = useState(true);
  const handleSubmit = async (event) => {
    event.preventDefault();

    const dataToSend = {
      name: event.target.name.value,
      mobile: event.target.mobile.value,
      email: event.target.email.value,
      state: event.target.state.value,
    };
    console.log("dataToSend", dataToSend);

    try {
      const response = await axios.post(
        "https://api.studentrays.com/api/freesessions",
        dataToSend
      );
      console.log("Data sent successfully:", response.data);
      setformSummit(false);
    } catch (err) {
      console.error("Session failed:", err); // Log the full error object for debugging
      // You can handle the error in a more user-friendly way, such as displaying a message to the user.
    }
  };
  return (
    <>
      <div className="front">
        <div>
          <Navtop />
          <Navb />
        </div>
        <div id="session">
          <div className="sessionTop">
            Book your free session
            {/* Whether you're a student seeking academic excellence, a professional
          looking to upskill, or an enthusiast pursuing a new passion, we're
          here to support your educational journey. */}
          </div>
          <div className="sessionArea">
            {/* <img src={IMG5} alt="img" className="imageControl" /> */}
            {/* <button className="homeAreaBtn" href="/session">
              book your <span className="homeAreaSpan"> free Assessment</span>
            </button> */}
            {formSummit && (
              <Form onSubmit={handleSubmit} className="sessionform">
                <h3>Book your Free Session</h3>
                <p>Learn from India's best teachers</p>
                <h4>Enter Your Details</h4>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter Your Name"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="number"
                    name="mobile"
                    placeholder="Enter Your Mobile No."
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter your Email"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    name="state"
                    placeholder="Enter your State"
                  />
                </Form.Group>
                <Button type="submit">Submit</Button>
              </Form>
            )}
            {!formSummit && (
              <div className="formSummited">
                Thanks for your interest ,Our team will contact with you shortly
              </div>
            )}
          </div>
        </div>
      </div>
      <ScrollButton />
      <Footer />
    </>
  );
};

export default Session;
