export const userColumns = [
    // { field: "_id", headerName: "ID", width: 100 },
    {
      field: "name",
      headerName: "Name",
      width: 130,
    },
    {
      field: "email",
      headerName: "Email",
      width: 280,
    },
  
    {
      field: "complaintext",
      headerName: "Complaintext",
      width: 550,
    }
    // {
    //   field: "isTeacher",
    //   headerName: "teacher",
    //   width: 100,
    // },
    // {
    //   field: "phone",
    //   headerName: "Phone",
    //   width: 100,
    // },
  ];
export const freesessionColumns = [
    // { field: "_id", headerName: "ID", width: 100 },
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
    },
  
    {
      field: "state",
      headerName: "State",
      width: 200,
    }
    // {
    //   field: "isTeacher",
    //   headerName: "teacher",
    //   width: 100,
    // },
    // {
    //   field: "phone",
    //   headerName: "Phone",
    //   width: 100,
    // },
  ];
  
export const usersColumns = [
    // { field: "_id", headerName: "ID", width: 70 },
    {
      field: "user",
      headerName: "User",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img className="cellImg" src={params.row.img || "https://i.ibb.co/MBtjqXQ/no-avatar.gif"} alt="avatar" />
            {params.row.username}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 230,
    },
  
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
    },
    {
      field: "state",
      headerName: "State",
      width: 140,
    },
    {
        field: "grade",
        headerName: "Grade",
        width: 100,
    },
    {
      field: "section",
      headerName: "Section",
      width: 100,
    },
  ];
  