import React from "react";
import "./dashboardUserData.css"
const DashboardUserData = (props) => {
    console.log("props",props);
  const date = new Date();
  const hour = date.getHours();
  let greeting;
  if (hour < 12) {
    greeting = "Good Morning";
  } else if (hour < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }
  return (
    <div className="demowrapper">
      <h1>
        {greeting}, {props.name}!
      </h1>
      <p>course:{props.course}</p>
      <p>grade:{props.grade}</p>
      <p>phone:{props.phone}</p>
      <p>email:{props.email}</p>
      <p>state:{props.state}</p>
    </div>
  );
};

export default DashboardUserData;
